import React, { createContext, useState } from "react";

export const ProductosContext = createContext();

export const ProductosContextProvider = ({ children }) => {
    
  const [productosState, setProductosState] = useState({
    producto:{},
    carrito:[]
  });

  return (
    <ProductosContext.Provider
      value={{
        productosState,
        setProductosState,
      }}
    >
      {children}
    </ProductosContext.Provider>
  );
};
