import { ProductosContext } from "context/productos_context";
import React, { useState, useEffect, useContext } from "react";
import carritoVacio from '../assets/images/carrito.png'
import { useNavigate } from "react-router-dom";
import { urlBack } from "utils/urlEnpoint";
import PopUp from "components/popUp";

const ProductDisplay = () => {
    const { productosState, setProductosState } = useContext(ProductosContext);
    const [carrito, setCarrito] = useState([]);
    const [precioProductos, setPrecioProductos] = useState(0);
    const [popUp, setPopUp] = useState(false)
    const navigate = useNavigate();

    const handleGoToShop = () => {
        navigate("/farmacia");
    };

    useEffect(() => {
        const carritoLocalStorage = localStorage.getItem("carrito");
        if (carritoLocalStorage) {
            const carritoParseado = JSON.parse(carritoLocalStorage).map((producto) => ({
                ...producto,
                cantidad: producto.cantidad || 1,
            }));
            setCarrito(carritoParseado);
            calcularPrecioTotal(carritoParseado);
        }
    }, [productosState]);

    const calcularPrecioTotal = (carrito) => {
        const total = carrito.reduce(
            (acc, producto) =>
                acc + (producto.monto_descuento * producto.cantidad || 0),
            0
        );
        setPrecioProductos(total);
    };

    const handleCantidad = (index, increment) => {
        const nuevoCarrito = [...carrito];
        nuevoCarrito[index].cantidad += increment;

        if (nuevoCarrito[index].cantidad <= 0) {
            // Si la cantidad es 0 o menor, eliminar el producto
            nuevoCarrito.splice(index, 1);
        }

        setCarrito(nuevoCarrito);
        setProductosState({ ...productosState, carrito: nuevoCarrito })
        localStorage.setItem("carrito", JSON.stringify(nuevoCarrito));
        calcularPrecioTotal(nuevoCarrito);
    };

    const handleShowPopUp = () => {
        setPopUp(true)
    }
    const handleClosePopUp = () => {
        setPopUp(false)
    }
    return (
        <>
            <section
                style={{
                    margin: '50px',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    height: carrito.length > 2 ? '' : '700px'
                }}
            >
                {carrito.length === 0 ? (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: "600px" }}
                    >
                        <img src={carritoVacio} alt="carrito" />
                        <div className="text-cafe text-center my-3">
                            Tu carrito está vacío, <br />
                            ¡ llénalo con el cuidado que tu piel merece !
                        </div>
                        <div
                            className="btn-goShop"
                            onClick={() => {
                                handleGoToShop();
                            }}
                        >
                            Ir a la Tienda
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="product">
                            <div className="text-center">
                                <h3 className="text-cafe text-bold my-3">Total del carrito: ${precioProductos.toFixed(2)}</h3>
                            </div>
                            <div onClick={() => { handleShowPopUp() }} className="btn-goShop text-cafe text-medium text-bold my-3" >Comprar</div>
                            {carrito.map((producto, i) => (
                                <div className="d-flex mb-5" key={i}>
                                    <img
                                        style={{ width: "200px" }}
                                        src={`${urlBack}${producto.imagenes[0]}`}
                                        alt="imagen producto"
                                    />
                                    <div className="d-flex flex-column ms-4 justify-content-center align-items-center">
                                        <div><span className="text-cafe text-bold">Nombre:</span> {producto.nombreProducto}</div>
                                        <div className="my-3"> <span className="text-cafe text-bold">Precio:</span> {producto.monto_descuento}</div>
                                        <div>
                                            <span className="text-cafe text-bold">
                                                Cantidad:
                                            </span>
                                            <button
                                                className="btn-add"
                                                onClick={() => handleCantidad(i, -1)}
                                                style={{ margin: "0 5px" }}
                                            >
                                                -
                                            </button>
                                            {producto.cantidad}
                                            <button
                                                className="btn-add"
                                                onClick={() => handleCantidad(i, 1)}
                                                style={{ margin: "0 5px" }}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </section>
            {popUp ?
                <PopUp carrito={carrito} onclose={() => { handleClosePopUp() }} />
                :
                null
            }
        </>
    );
};

const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);

export default function Checkout() {
    const [message, setMessage] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    return message ? (
        <Message message={message} />
    ) : (
        <ProductDisplay />
    );
}