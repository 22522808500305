import Testimonios from "pages/Inicio/sections/Testimonios";
import ReseñaDoctor from "pages/Inicio/sections/ReseñaDoctor";
import Mapa from "pages/Inicio/sections/Mapa";
import BandaPromociones from "pages/Inicio/sections/BandaPromociones";
// import routes from "routes";
import Centroderma from "./sections/centroderma";
import vector from '../../assets/images/Vector.png'
import vector1 from '../../assets/images/Vector1.png'
import Serviciosderma from "./sections/serviciosderma";



export default function Inicio() {
  return (
    <>
      <Centroderma />
      <div className="d-flex justify-content-end" style={{ marginTop: '-350px', zIndex: '100' }}>
        <img src={vector} alt="vector" />
      </div>
      <Serviciosderma/> 
      <div className="d-flex justify-content-start" style={{ marginTop: '-120px', zIndex: '1' }}>
        <img src={vector1} alt="vector" />
      </div>
      <BandaPromociones />
      <ReseñaDoctor />
      <Testimonios />
      <Mapa />
    </>
  );
}
