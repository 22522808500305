

function Favoritopiel() {
  return (
    <div className="container">
            <div className="bg-favoritos">
                <div className="d-flex justify-content-end align-items-end">
                    <div className="col"></div>
                    <div className="col">
                        <div className="text-cafe text-big text-bolder mt-5">Favoritos</div>
                        <div className="text-rosa text-medium my-3">Lo mejor para tu piel, elegido por ti.</div>
                        <div className="text-rosa">Descubre nuestros tratamientos más destacados, diseñados para cuidar y revitalizar tu piel. Cada uno ha sido cuidadosamente seleccionado por su efectividad y popularidad entre nuestros pacientes.</div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Favoritopiel