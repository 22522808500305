import persona from '../../../assets/images/group9.png'
import reloj from '../../../assets/images/group10.png'
import calendario from '../../../assets/images/group11.png'

function Contador() {
    return (
        <div className="bg-gradiente-rosa" style={{marginTop:'100px'}}>
            <div className='container pb-5' >
                <div className="d-flex justify-content-center align-items-center" >
                    <div className='text-center' style={{marginTop:'-70px', width:'15%'}}>
                        <img src={persona} alt="persona" />
                        <div className="text-cafe text-between text-bolder my-3">5,234</div>
                        <div className="text-cafe text-bolder my-3">Pacientes Atendidos</div>
                        <div className="text-cafe my-3">Número total de pacientes que hemos atendido con éxito.</div>
                    </div>
                    <div className='text-center mx-5' style={{marginTop:'-70px', width:'15%' }}>
                        <img src={reloj} alt="persona" />
                        <div className="text-cafe text-between text-bolder my-3">3,400+</div>
                        <div className="text-cafe text-bolder my-3">Horas de Tratamiento</div>
                        <div className="text-cafe my-3">Horas dedicadas a proporcionar cuidados y tratamientos especializados.</div>
                    </div>
                    <div className='text-center' style={{marginTop:'-70px', width:'15%'}}>
                        <img src={calendario} alt="persona" />
                        <div className="text-cafe text-between text-bolder my-3">24/7</div>
                        <div className="text-cafe text-bolder my-3">Consultas</div>
                        <div className="text-cafe my-3">Consultas realizadas por nuestro equipo de especialistas en dermatología</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contador