import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Inicio from "./pages/Inicio/Inicio";

import routes from "routes";
import Gracias from "pages/Inicio/gracias";
import Header from "components/header";
import { NotificacionContextProvider } from "context/notificacion_context";
import NotificationComponent from "components/notificacion_ui";
import Footer from "components/footer";
import BotonFlotante from "components/BotonFlotante/BotonFlotante";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { ProductosContextProvider } from "context/productos_context";


export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duración de las animaciones (en milisegundos)
    });
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <NotificacionContextProvider>
      <ProductosContextProvider>
        <NotificationComponent />
        <Header />
        <CssBaseline />
        <BotonFlotante />
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Inicio />} />
          <Route path="/graciasportucompra" element={<Gracias />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </ProductosContextProvider>
    </NotificacionContextProvider>
  );
}
