import IconUI from "components/icon_ui";
import { useState } from "react";
import { useEffect } from "react";
import { urlBack } from "utils/urlEnpoint";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { MdEditDocument } from "react-icons/md";
import { NotificacionContext } from "context/notificacion_context";
import { useContext } from "react";
import MasInfoVenta from "pages/Admin/sections/masInfoVenta";
import MostrarTicket from "pages/Admin/sections/mostrarTicket";

function Compras() {
    const { showNotification } = useContext(NotificacionContext)
    const [ventas, setVentas] = useState([])
    const [popupMoreInfo, setPopupMoreInfo] = useState(false)
    const [popupShowPDF, setPopupShowPDF] = useState(false)

    const [ventaSeleccionada, setVentaSeleccionada] = useState({})
    const navigate = useNavigate()


    const getCompras = async (url, token) => {
        try {
            const resp = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                mode: "cors",
                "Authorization": `Bearer ${token}`
            });

            if (!resp.ok) {
                throw new Error(`Error HTTP! status: ${resp.status}`);
            }

            const json = await resp.json();
            return json;
        } catch (error) {
            console.error("Error al obtener los productos:", error);
            return { error: error.message };
        }
    }


    const fetchCompras = async () => {
        const token = localStorage.getItem("token")
        const ventas = await getCompras(`${urlBack}compras.php`, token);
        if (ventas) {
            if (ventas.error === 'Error HTTP! status: 401') {
                localStorage.removeItem("token")
                localStorage.removeItem("nombre")
                localStorage.removeItem("tipoUsuario")
                showNotification(
                    "warning",
                    "Se caducó la Sesión",
                    "vuelve a Ingresar de nuevo",
                    null,
                    5
                );
                navigate("/login")
            } else {
                if (ventas.error === "No se encontraron compras.") {
                    setVentas([])
                } else {
                    setVentas(ventas.data)
                }
            }
        }
    };




    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            fetchCompras();
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line 
    }, []);
    const columns = [
        {
            name: "email_cliente",
            label: "Cliente",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let email_cliente = ventas[dataIndex]?.email_cliente;
                    return (
                        <div >
                            {email_cliente}
                        </div>
                    );
                },
            },
        },
        {
            name: "total",
            label: "Total",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let total = ventas[dataIndex]?.total;
                    return (
                        <div>
                            {total}
                        </div>
                    );
                },
            },
        },
        {
            name: "fecha",
            label: "Fecha",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let fecha = ventas[dataIndex]?.fecha;
                    return (
                        <div>
                            {fecha}
                        </div>
                    );
                },
            },
        },

        {
            name: "acciones",
            label: "Acciones",

            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => {
                    return (
                        <>
                            <div className="d-flex">
                                <div className="btn-acciones-out" onClick={() => {
                                    setPopupMoreInfo(true)
                                    setVentaSeleccionada(ventas[dataIndex])
                                }}>
                                    <IconUI size={'15px'}>
                                        <IoEyeOutline />
                                    </IconUI>
                                </div>
                                <div className="btn-acciones-out" onClick={() => {
                                    setPopupShowPDF(true)
                                    setVentaSeleccionada(ventas[dataIndex])
                                }}>
                                    <IconUI size={'15px'}>
                                        <MdEditDocument />
                                    </IconUI>
                                </div>
                            </div>
                        </>
                    );
                },
            },
        },
    ];
    const options = {
        selectableRows: "none",
        tableBodyHeight: "auto",
        textLabels: {
            body: {
                // noMatch: isLoading ? <Loader /> : "Lo sentimos, no hay coincidencias en la busqueda",
                noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todo",
                title: "Filtros",
                reset: "Restaurar",
            },
            viewColumns: {
                title: "Ver columnas",
                titleAria: "Mostrar/Ocultar columnas en la tabla",
            },
            selectedRows: {
                text: "columna(s) selecciona(as)",
                delete: "Eliminar",
                deleteAria: "Eliminar filas seleccionadas",
            },
        },
        responsive: "standard",
        download: false,
        print: false,
        filter: false,
        viewColumns: false,

        // downloadOptions: {

        //     filename: 'Bitacora.csv',
        //     filterOptions: {
        //         useDisplayedColumnsOnly: false,
        //         useDisplayedRowsOnly: false,
        //     }
        // },
    };
    return (
        <>
            <div className='mt-3 bg-home'>
                <div className='text-cafe text-medium'>Mis Compras</div>

                <div className="mt-4">
                    {ventas.length === 0 ?
                        <div className="text-cafe mt-3">No cuentas con ningúna compra, si deseas adquirir productos, cierra sesión y visítanos en nuestra farmacia en línea.</div>
                        :
                        null
                    }
                    {ventas.length > 0 ?
                        <MUIDataTable
                            style={{ Overflow: "hidden" }}
                            title={"Registros"}
                            data={ventas}
                            columns={columns}
                            options={options}
                        // onDownload={false}
                        />
                        :
                        null
                    }

                </div>
            </div>
            {popupMoreInfo ?
                <MasInfoVenta data={ventaSeleccionada} onclose={() => {
                    setPopupMoreInfo(false)
                }} />
                :
                null
            }
            {popupShowPDF ?
                <MostrarTicket data={ventaSeleccionada} onclose={() => {
                    setPopupShowPDF(false)
                }} />
                :
                null
            }

        </>
    )
}

export default Compras