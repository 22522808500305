import React, { useState, useContext } from "react";
import OverLayIU from "../../../components/overlay_ui";

import IconUI from "../../../components/icon_ui";
import { GrClose } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { urlBack } from "../../../utils/urlEnpoint";
import { NotificacionContext } from "context/notificacion_context";
import { useNavigate } from "react-router-dom";


const enviarData = async (url, producto, images, token) => {
    const formData = new FormData();
    formData.append(`nombre`, producto.nombre);
    formData.append(`descripcion`, producto.descripcion);
    formData.append(`precio`, producto.precio);
    formData.append(`tipo_descuento`, producto.tipo_descuento);
    formData.append(`stock`, producto.stock);
    let descuento;
    if (producto.tipo_descuento === "Fijo") {
        descuento = producto.precio - producto.monto_descuento

    } else {
        if (producto.tipo_descuento === "Porcentaje") {
            descuento = producto.precio - (producto.monto_descuento * producto.precio) / 100
        } else {
            descuento = 0
        }
    }
    formData.append(`monto_descuento`, descuento);
    images.forEach((image, index) => {
        formData.append(`imagenes[${index}]`, image);
    });
    try {
        const resp = await fetch(url, {
            method: "POST",
            body: formData,
            headers: {
                // "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            mode: "cors",
        });

        if (!resp.ok) {
            // const errorText = await resp.text();
            throw new Error(`Error en la respuesta del servidor: ${resp.status}`);
        }

        return await resp.json();
    } catch (error) {
        console.error("Error al enviar los datos:", error);
        return { error: error.message };
    }
};

export default function CrearProducto({ onclose }) {
    let navigate = useNavigate()
    const { showNotification } = useContext(NotificacionContext)
    const [producto, setProducto] = useState({
        nombre: "",
        descripcion: "",
        precio: "",
        stock: "",
        tipo_descuento: "Fijo",
        monto_descuento: "",
    })
    const [espera, setEspera] = useState(false)
    const [error, setError] = useState(false)


    const handleChangeName = (text) => {
        setProducto({ ...producto, nombre: text })
    }
    const handleChangeDescription = (text) => {
        setProducto({ ...producto, descripcion: text })
    }
    const handleChangePrice = (text) => {
        setProducto({ ...producto, precio: text })
    }

    const handleChangeStock = (text) => {
        setProducto({ ...producto, stock: text })
    }
    const handleChangeProductType = (text) => {
        setProducto({ ...producto, tipo_descuento: text })
    }
    const handleChangeDescuento = (text) => {
        setProducto({ ...producto, monto_descuento: text })
    }

    const [images, setImages] = useState([]);

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);

        if (files.length + images.length > 5) {
            // alert("Solo puedes subir hasta 5 imágenes.");
            showNotification(
                "error",
                "Imagenes de Productos",
                "Solo puedes subir hasta 5 imágenes.",
                null,
                5
            );
            return;
        }

        // const newImages = files.map((file) => URL.createObjectURL(file));
        setImages((prevImages) => [...prevImages, ...files]);
        // setImages((prevImages) => [...prevImages, ...newImages]);
    };

    const removeImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };



    const handleCreateProduct = async () => {
        let token = localStorage.getItem("token")

        setEspera(true);

        const respJson = await enviarData(`${urlBack}productoss.php`, producto, images, token);
      
        if (respJson.registrado) {
            showNotification(
                "success",
                "Producto Añadido",
                "La operación se realizó correctamente",
                null,
                5
            );
            setProducto({
                nombre: "",
                descripcion: "",
                precio: "",
                stock: "",
                tipo_descuento: "Fijo",
                monto_descuento: "",
            })
            setImages([])
            onclose(true)
        } else {
            if (respJson.error === 'Error en la respuesta del servidor: 401') {
                showNotification(
                    "warning",
                    "Se caducó la Sesión",
                    "vuelve a Ingresar de nuevo",
                    null,
                    5
                );
                navigate("/login")
            } else {
                setError(respJson.error);
            }
        }
        setEspera(false);
    };



    return (
        <>
            <OverLayIU
                onClick={() => {
                    onclose();
                }}
            />
            <div className="wizard-max-1000">
                <div className="d-flex mb-3">
                    <div className="col ">  </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            onclose();
                        }}
                    >
                        <IconUI size={15}>
                            <GrClose></GrClose>
                        </IconUI>
                    </div>
                </div>
                <div className="d-flex flex-column justify-conten-center align-items-center" >
                    <div className="text-cafe text-bold">Crear Producto</div>
                    <div className="text-gray mt-3">Llena todos los datos:</div>
                    <div className="d-flex" style={{ width: '70%' }}>
                        <div className="mt-3">
                            <div className="my-3 text-gray text-bold">Nombre :</div>
                            <div className="d-flex">
                                <input
                                    type="text"
                                    className="input-text"
                                    placeholder="Nombre"
                                    value={producto.nombre}
                                    onChange={(evt) => {
                                        handleChangeName(evt.target.value);
                                    }}
                                />
                            </div>

                            <div className="my-3 text-gray text-bold">Precio :</div>
                            <div className="d-flex">
                                <input
                                    type="number"
                                    className="input-text"
                                    placeholder="Precio"
                                    value={producto.precio}
                                    onChange={(evt) => {
                                        handleChangePrice(evt.target.value);
                                    }}
                                />
                            </div>


                        </div>
                        <div className="mt-3 mx-3">
                            <div className="my-3 text-gray text-bold">Stock :</div>
                            <div className="d-flex">
                                <input
                                    type="Number"
                                    className="input-text"
                                    placeholder="Stock"
                                    value={producto.stock}
                                    onChange={(evt) => {
                                        handleChangeStock(evt.target.value);
                                    }}
                                />
                            </div>
                            <div className="my-3  text-gray text-bold">Tipo de Descuento :</div>
                            <select className="select-input" defaultValue={producto.tipo_descuento} onChange={(evt) => {
                                handleChangeProductType(evt.target.value);
                            }}>
                                <option value="Fijo">Fijo</option>
                                <option value="Porcentaje">Porcentaje</option>
                            </select>
                            <div className="ps-3" style={{ marginTop: '-35px', marginBottom: '27px' }}>{producto.tipo_descuento === "Fijo" ? 'Fijo' : 'Porcentaje'}</div>
                            <div className="my-3 text-gray text-bold">Descuento :</div>
                            <div className="d-flex">
                                <input
                                    type="Number"
                                    className="input-text"
                                    placeholder="Descuento"
                                    value={producto.monto_descuento}
                                    onChange={(evt) => {
                                        handleChangeDescuento(evt.target.value);
                                    }}
                                />
                            </div>


                        </div>

                    </div>
                    <div className="mt-3" style={{ width: '70%' }}>

                        <div className=" text-gray text-bold">Precio con Descuento : $ {producto.tipo_descuento === "Fijo" ? producto.precio - producto.monto_descuento : (producto.precio - (producto.monto_descuento * producto.precio) / 100)}</div>
                        <div className="my-3 text-gray text-bold">Descripción :</div>
                        <div className="d-flex">
                            <textarea
                                type="text"
                                className="input-area"
                                placeholder="Descripción"
                                rows={4}
                                value={producto.descripcion}
                                onChange={(evt) => {
                                    handleChangeDescription(evt.target.value);
                                }}
                            />
                        </div>

                        <div>
                            <div className="d-flex align-items-center">
                                <div className="col">
                                    <div className="my-3 text-gray text-bold">Cargar imágenes:</div>
                                </div>
                                <div className="col">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        onChange={handleImageUpload}
                                    />
                                </div>

                            </div>
                            <div className="row" style={{ marginTop: "20px" }}>
                                {images.map((image, index) => (
                                    <div
                                        className="col"
                                        key={index}
                                        style={{
                                            marginBottom: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src={URL.createObjectURL(image)}
                                            alt={`uploaded-${index}`}
                                            style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                        />
                                        <div style={{ marginLeft: "10px" }} onClick={() => removeImage(index)} className="cursor-pointer">
                                            <IconUI color={'gray'} size={'20px'} >
                                                <MdDelete />
                                            </IconUI>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {espera ?

                            <div className="mt-5 d-flex justify-content-center">
                                <div className="loader"></div>
                            </div>
                            :
                            <div className="d-flex flex-column justify-conten-center align-items-center">
                                <div className="text-gray mt-3 btn-rosa text-center" onClick={() => {
                                    handleCreateProduct()
                                }}>Crear Producto</div>
                            </div>
                        }

                    </div>


                    <div className="text-danger mt-2 text-center" style={{ height: '30px' }}>{error ? error : ''}</div>

                </div>

            </div>
        </>
    );
}
