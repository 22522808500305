import logoBlanco from '../assets/images/logoPeroliBlanco.png'
import facebookB from '../assets/images/FacebookB.png'
import instagramB from '../assets/images/InstagramB.png'
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import IconUI from './icon_ui'

function Footer() {
  return (
    <div className="bg-footer">
      <div className="container py-5">
        <div className="d-flex text-rosa" style={{ borderBottom: '1px solid gray' }}>
          <div><img className='p-3' src={logoBlanco} alt="Logo Peroli Blanco" /></div>
          <div className='mx-4 my-3'>
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-center align-items-center me-4' style={{ backgroundColor: '#AC8B8C', borderRadius: '50%', width: '40px', height: '40px' }}>
                <IconUI size={'25px'} color={'white'}>
                  <FiPhone />
                </IconUI>
              </div>
              <div>352 526 1474</div>
            </div>
            <div className='d-flex align-items-center my-3'>
              <div className='d-flex justify-content-center align-items-center me-4' style={{ backgroundColor: '#AC8B8C', borderRadius: '50%', width: '40px', height: '40px' }}>
                <IconUI size={'25px'} color={'white'}>
                  <MdOutlineMailOutline />
                </IconUI>
              </div>
              <div>dermaperoli@gmail.com</div>
            </div>
            <div className='d-flex align-items-center'>

              <div className='d-flex justify-content-center align-items-center me-4' style={{ backgroundColor: '#AC8B8C', borderRadius: '50%', width: '40px', height: '40px' }}>
                <IconUI size={'25px'} color={'white'}>
                  <FaMapMarkerAlt />
                </IconUI>
              </div>
              <div>Calle General Enrique Ramírez #48, Plaza Gran Vía Local 4, Fracc. Peña, C.P. 59389</div>
            </div>
          </div>
          <div className='d-flex justify-content-end align-items-end my-3'>
            <div className='me-3'>
              <a href="https://www.facebook.com/CentroDermaPeroli/" target='_blank' rel="noreferrer" >
                <img src={facebookB} alt="Facebook" />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/CentroDermaPeroli/" target='_blank' rel="noreferrer" >
                <img src={instagramB} alt="Instagram" />
              </a>
            </div>
          </div>
        </div>
        <div className='text-white text-center py-3'>Aviso de Publicidad, COFEPRIS: 2316062000A00001</div>
        <div className='text-white text-center py-3'>Todos los derechos reservados. © 2024 Peroli Centro Dermatológico</div>
      </div>
    </div>
  )
}

export default Footer