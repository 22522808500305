import React, { useState,useContext } from "react";
import OverLayIU from "../../../components/overlay_ui";

import IconUI from "../../../components/icon_ui";
import { GrClose } from "react-icons/gr";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { urlBack } from "../../../utils/urlEnpoint";
import { NotificacionContext } from "context/notificacion_context";


const enviarData = async (url, data, token) => {
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      mode: "cors",
    });

    if (!resp.ok) {
      const errorText = await resp.text();
      throw new Error(`Error en la respuesta del servidor: ${resp.status} ${errorText}`);
    }

    return await resp.json();
  } catch (error) {
    console.error("Error al enviar los datos:", error);
    return { error: error.message };
  }
};

export default function CrearUsuario({ onclose }) {
    const {showNotification} = useContext(NotificacionContext)
  const [usuario, setUsuario] = useState({
    correo: "",
    nombre: "",
    apellidos: "",
    clave: "",
    idTipoUsuario: "1",
    celular: "",
    calle: "",
    colonia:"",
    numero: "",
    numero_interior: "",
    codigo_postal: "",
    estado: "",
    ciudad: "",
    referencias: ""
  })
  const [espera, setEspera] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false)


  const handleChangeEmail = (text) => {
    setUsuario({ ...usuario, correo: text })
  }
  const handleChangeContraseña = (text) => {
    setUsuario({ ...usuario, clave: text })
  }
  const handleChangeName = (text) => {
    setUsuario({ ...usuario, nombre: text })
  }
  const handleChangeSecondName = (text) => {
    setUsuario({ ...usuario, apellidos: text })
  }
  const handleChangeUserType = (text) => {
    setUsuario({ ...usuario, idTipoUsuario: text })
  }
  const handleChangePhone = (text) => {
    setUsuario({ ...usuario, celular: text })
  }
  const handleChangeStreet = (text) => {
    setUsuario({ ...usuario, calle: text })
  }
  const handleChangeColonia = (text) => {
    setUsuario({ ...usuario, colonia: text })
  }
  const handleChangeNumber = (text) => {
    setUsuario({ ...usuario, numero: text })
  }
  const handleChangeNumberInt = (text) => {
    setUsuario({ ...usuario, numero_interior: text })
  }
  const handleChangeZipCode = (text) => {
    setUsuario({ ...usuario, codigo_postal: text })
  }
  const handleChangeState = (text) => {
    setUsuario({ ...usuario, estado: text })
  }
  const handleChangeCity = (text) => {
    setUsuario({ ...usuario, ciudad: text })
  }
  const handleChangeReferences = (text) => {
    setUsuario({ ...usuario, referencias: text })
  }
  const handleCreateUser = async () => {
    let token = localStorage.getItem("token")
    setEspera(true);
    const respJson = await enviarData(`${urlBack}usuarioss.php`, usuario, token);
    if (respJson.registrado) {
      showNotification(
        "success",
        "Usuario Añadido",
        "La operación se realizó correctamente",
        null,
        5
      );
      setUsuario({
        correo: "",
        nombre: "",
        apellidos: "",
        clave: "",
        idTipoUsuario: "1",
        celular: "",
        calle: "",
        colonia:"",
        numero: "",
        numero_interior: "",
        codigo_postal: "",
        estado: "",
        ciudad: "",
        referencias: ""
      })
      onclose(true)
    } else {
      setError(respJson.error);
    }
    setEspera(false);
  };



  return (
    <>
      <OverLayIU
        onClick={() => {
          onclose();
        }}
      />
      <div className="wizard-max-1000">
        <div className="d-flex mb-3">
          <div className="col ">  </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              onclose();
            }}
          >
            <IconUI size={15}>
              <GrClose></GrClose>
            </IconUI>
          </div>
        </div>
        <div className="d-flex flex-column justify-conten-center align-items-center">
          <div className="text-cafe text-bold">Crear Usuario</div>
          <div className="text-gray mt-3">Llena todos los datos:</div>
          <div className="d-flex">
            <div className="mt-3">
              <div className="my-3 text-gray text-bold">Correo :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="correo"
                  value={usuario.correo}
                  onChange={(evt) => {
                    handleChangeEmail(evt.target.value);
                  }}
                />
              </div>
              <div className="my-3 text-gray text-bold">Nombre :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Nombre"
                  value={usuario.nombre}
                  onChange={(evt) => {
                    handleChangeName(evt.target.value);
                  }}
                />
              </div>
              <div className="my-3 text-gray text-bold">Apellidos :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Apellidos"
                  value={usuario.apellidos}
                  onChange={(evt) => {
                    handleChangeSecondName(evt.target.value);
                  }}
                />
              </div>
              <div className="my-3  text-gray text-bold">Contraseña :</div>
              <div className="d-flex">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input-text"
                  placeholder="Ingrese su contraseña"
                  value={usuario.clave}
                  onChange={(evt) => {
                    handleChangeContraseña(evt.target.value);
                  }}
                />
                <div className="text-cafe text-medium p-2 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => {
                  setShowPassword(!showPassword)
                }}>
                  {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </div>
              </div>
              <div className="my-3 text-gray text-bold">Código Postal :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Código Postal"
                  value={usuario.codigo_postal}
                  onChange={(evt) => {
                    handleChangeZipCode(evt.target.value);
                  }}
                />
              </div>


            </div>
            <div className="mt-3 mx-3">
              <div className="my-3 text-gray text-bold">Celular :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Celular"
                  value={usuario.celular}
                  onChange={(evt) => {
                    handleChangePhone(evt.target.value);
                  }}
                />
              </div>
              <div className="my-3 text-gray text-bold">Calle :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Calle"
                  value={usuario.calle}
                  onChange={(evt) => {
                    handleChangeStreet(evt.target.value);
                  }}
                />
              </div>
              <div className="my-3 text-gray text-bold">Colonia :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Colonia"
                  value={usuario.colonia}
                  onChange={(evt) => {
                    handleChangeColonia(evt.target.value);
                  }}
                />
              </div>
              <div className="my-3 text-gray text-bold">Numero Exterior :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Numero"
                  value={usuario.numero}
                  onChange={(evt) => {
                    handleChangeNumber(evt.target.value);
                  }}
                />
              </div>
              <div className="my-3 text-gray text-bold">Numero Interior :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Numero Interior"
                  value={usuario.numero_interior}
                  onChange={(evt) => {
                    handleChangeNumberInt(evt.target.value);
                  }}
                />
              </div>


            </div>
            <div className="mt-3">
              <div className="my-3  text-gray text-bold">Tipo de Usuario :</div>
              <select className="select-input" defaultValue={usuario.idTipoUsuario} onChange={(evt) => {
                handleChangeUserType(evt.target.value);
              }}>
                <option value="1">Administrador</option>
                <option value="2">Usuario</option>
              </select>
              <div className="ps-3" style={{marginTop:'-35px', marginBottom:'27px'}}>{usuario.idTipoUsuario === "1"?'Administrador':'Usuario'}</div>
              <div className="my-3 text-gray text-bold">Estado :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Estado"
                  value={usuario.estado}
                  onChange={(evt) => {
                    handleChangeState(evt.target.value);
                  }}
                />
              </div>
              <div className="my-3 text-gray text-bold">Ciudad :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Ciudad"
                  value={usuario.ciudad}
                  onChange={(evt) => {
                    handleChangeCity(evt.target.value);
                  }}
                />
              </div>
              <div className="my-3 text-gray text-bold">Referencias :</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="input-text"
                  placeholder="Referencias"
                  value={usuario.referencias}
                  onChange={(evt) => {
                    handleChangeReferences(evt.target.value);
                  }}
                />
              </div>
              {espera ?

                <div className="mt-5 d-flex justify-content-center">
                  <div className="loader"></div>
                </div>
                :
                <div className="d-flex flex-column justify-conten-center align-items-center">
                  <div className="text-gray mt-3 btn-rosa text-center" onClick={() => {
                    handleCreateUser()
                  }}>Crear Usuario</div>
                </div>
              }

            </div>
          </div>


          <div className="text-danger mt-2 text-center" style={{ height: '30px' }}>{error ? error : ''}</div>

        </div>

      </div>
    </>
  );
}
