import Contacto from "./sections/contacto";

function Contactanos() {
  return (
    <div>
      <Contacto/>
    </div>
  );
}

export default Contactanos;
