import OverLayIU from "../../../components/overlay_ui";
import IconUI from "../../../components/icon_ui";
import { GrClose } from "react-icons/gr";
import { urlBackslash } from "utils/urlEnpoint";

export default function MostrarTicket({ onclose, data }) {

    return (
        <>
            <OverLayIU
                onClick={() => {
                    onclose();
                }}
            />
            <div className="wizard-max-1000">
                <div className="d-flex mb-3">
                    <div className="col ">  </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            onclose();
                        }}
                    >
                        <IconUI size={15}>
                            <GrClose></GrClose>
                        </IconUI>
                    </div>
                </div>
                <div className="d-flex flex-column justify-conten-center align-items-center mb-3">
                    <div className="text-cafe text-bold">Ticket de Compra</div>
                </div>
                <iframe
                    src={`${urlBackslash}${data.ticket_url}`}
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                    title="Ticket de compra"
                />



            </div>
        </>
    );
}
