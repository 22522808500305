
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { urlBack } from "utils/urlEnpoint";
import { NotificacionContext } from "context/notificacion_context";


const enviarData = async (url, data) => {
    try {
        const resp = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        });

        if (!resp.ok) {
            throw new Error(`Error en la respuesta del servidor: ${resp.status}`);
        }

        return await resp.json();
    } catch (error) {
        console.error("Error al enviar los datos:", error);
        return { error: error.message };
    }
};

export default function RecuperarContraseña() {
    const { showNotification } = useContext(NotificacionContext)
    let navigate = useNavigate();
    const [error, setError] = useState(null);
    const [espera, setEspera] = useState(false);
    const [state, setState] = useState({
        email: '',
    })

    const handleRestablecerContrasena = async () => {
        setEspera(true);

        const data = {
            email: state.email,
        };
        if (state.email) {
            const respJson = await enviarData(`${urlBack}recuperarContrasena.php`, data);

            if (respJson.message === 'Correo enviado con éxito.') {
                showNotification(
                    "success",
                    "Envío de Correo",
                    "La operación se realizó correctamente",
                    null,
                    5
                );
                navigate("/login")
            } else {
                setError(respJson.error);
                setEspera(false);
            }
        } else {
            setError('Favor de ingresar los datos.')
        }
        setEspera(false);
    };




    // Limpia el mensaje de error después de 5 segundos o si el usuario empieza a escribir
    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => setError(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [error]);


    const handleChangeEmail = (text) => {
        setState({ ...state, email: text })
    }



    return (
        <div className="container">
            <div className="bg-login">
                <div className="d-flex">
                    <div className="col"></div>
                    <div className="col">
                        <div className="text-big text-cafe">Restablecer contraseña</div>
                        <div className="card-shadow p-3">
                            <div className="text-rosa mt-2">Siga las instrucciones através de su correo electrónico.</div>
                            <div className="text-gray text-bold my-3">Correo Electrónico</div>
                            <div className="my-2 d-flex">
                                <input
                                    placeholder="Email"
                                    className="input-text"
                                    type="email"
                                    value={state.email}
                                    onChange={(evt) => {
                                        handleChangeEmail(evt.target.value);
                                    }}
                                />
                                <div style={{ width: '40px', height: '30px' }}></div>
                            </div>

                            {espera ?

                                <div className="mt-5 d-flex justify-content-center">
                                    <div className="loader"></div>
                                </div>
                                :
                                <div className="d-flex flex-column justify-conten-center align-items-center">
                                    <div className="text-gray mt-3 btn-rosa text-center" onClick={() => {
                                        handleRestablecerContrasena()
                                    }}>Restablecer contraseña</div>
                                </div>
                            }
                            <div className="text-danger mt-2 text-center" style={{ height: '30px' }}>{error ? error : ''}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
