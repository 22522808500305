import location from '../../../assets/images/location.png'

export default function Mapa() {
  const mapaSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8897.731151958018!2d-102.02879744452459!3d20.34016798859304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842c1f138016e9af%3A0x6000895035d23beb!2sFarmacia%20Dermatol%C3%B3gica%20Peroli%20Sucursal%20Madero!5e0!3m2!1ses!2smx!4v1729111317333!5m2!1ses!2smx`;

  return (
    <div className="container my-5 py-3">
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <div className="text-rosa">¡Estamos más cerca de lo que crees!</div>
          <div className="text-cafe text-between text-bolder">Visítanos hoy mismo</div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="mx-4"><img src={location} alt="puntero" /></div>
          <div className="text-rosa">Encuentra el camino hacia una piel radiante en Peroli.</div>
        </div>
      </div>
      <div className="w-100 mt-5">
        <iframe
          title="Mapa"
          src={mapaSrc}
          className="w-100"
          style={{height:'600px'}}
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

