import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductosContext } from "context/productos_context";
import { urlBack } from "utils/urlEnpoint";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import IconUI from "components/icon_ui";

function DetalleProducto() {
    const { productosState } = useContext(ProductosContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const [currentIndex, setCurrentIndex] = useState(0);

    const producto = productosState?.producto;

    const cambiarImagenPrincipal = (index) => {
        setCurrentIndex(index);
    };

    const handleNextImage = () => {
        if (producto?.imagenes?.length) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % producto.imagenes.length);
        }
    };

    const handlePrevImage = () => {
        if (producto?.imagenes?.length) {
            setCurrentIndex(
                (prevIndex) =>
                    (prevIndex - 1 + producto.imagenes.length) % producto.imagenes.length
            );
        }
    };

    useEffect(() => {
        if (!producto || producto.idProducto !== id) {
            navigate("/farmacia");
        }
    }, [producto, id, navigate]);

    if (!producto) {
        return <div>Cargando producto...</div>;
    }

    return (
        <div className="container mt-4">
            <div className="row">
                {/* Carrusel de imágenes */}
                <div className="col-md-6 position-relative">
                    <div className="mb-3">
                        {producto.imagenes?.length > 0 ? (
                            <img
                                src={`${urlBack}${producto.imagenes[currentIndex]}`}
                                alt="Imagen principal"
                                className="img-carrousel"
                                style={{ width: "100%", height: "600px" }}
                            />
                        ) : (
                            <p>No hay imágenes disponibles para este producto.</p>
                        )}
                    </div>
                    {/* Flechas de navegación */}
                    {producto.imagenes?.length > 1 && (
                        <>
                            <button
                                className="btn-carrousel position-absolute"
                                style={{ top: "45%", left: "10px", transform: "translateY(-50%)" }}
                                onClick={handlePrevImage}
                            >
                                <IconUI size={'25px'}>
                                    <FaAngleLeft />
                                </IconUI>
                            </button>
                            <button
                                className="btn-carrousel position-absolute"
                                style={{ top: "45%", right: "10px", transform: "translateY(-50%)" }}
                                onClick={handleNextImage}
                            >
                                <IconUI size={'25px'}>
                                    <FaAngleRight />
                                </IconUI>
                            </button>
                        </>
                    )}
                    {/* Miniaturas */}
                    <div className="d-flex justify-content-center mt-2">
                        {producto.imagenes?.map((imagen, index) => (
                            <img
                                key={index}
                                src={`${urlBack}${imagen}`}
                                alt={`Miniatura ${index}`}
                                className={`img-thumbnail mx-2 ${currentIndex === index ? "border-rosa" : ""
                                    }`}
                                style={{ width: "70px", height: "70px", cursor: "pointer" }}
                                onClick={() => cambiarImagenPrincipal(index)}
                            />
                        ))}
                    </div>
                </div>

                {/* Información del producto */}
                <div className="col-md-6">
                    <h2 className="text-cafe text-bold">{producto.nombreProducto}</h2>
                    <div className="text-rosa my-2">STOCK: {producto.stock} disponibles</div>
                    <div className="mt-2">
                        <StarRating rating={productosState.producto.calificacionPromedio} />
                    </div>
                    <div className="text-cafe text-bold mt-4" style={{textDecoration:'line-through'}}>${producto.precio}</div>
                    <div className="text-cafe text-between text-bold mt-2" >${producto.monto_descuento} MXN</div>
                    <div className="mt-5">DESCRIPCION</div>
                    <div className="text-rosa my-3">{producto.descripcion}</div>
                </div>
            </div>

            {/* Comentarios */}
            <div className="mt-5">
                <h3>Comentarios</h3>
                {producto.comentarios?.length > 0 ? (
                    producto.comentarios.map((comentario, index) => (
                        <div key={index} className="border-bottom p-3 mb-3">
                            <div className="text-cafe text-bold">{comentario.nombreCompleto}</div>
                            <StarRating rating={comentario.calificacion} />
                            <small className="text-muted text-small">Fecha: {comentario.fecha}</small>
                            <p className="text-gray my-3">{comentario.comentario}</p>
                        </div>
                    ))
                ) : (
                    <p>No hay comentarios para este producto.</p>
                )}
            </div>
        </div>
    );
}

export default DetalleProducto;

const StarRating = ({ rating }) => {
    const stars = Array.from({ length: 5 }, (_, index) => {
        const starValue = index + 1;
        if (rating >= starValue) {
            return <div key={index} className="text-rosa-detalle"><FaStar /></div>; // Estrella llena
        } else if (rating > starValue - 1 && rating < starValue) {
            return <div key={index} className="text-rosa-detalle"><FaRegStarHalfStroke /></div>; // Estrella media
        } else {
            return <div key={index} className="text-rosa-detalle"><FaRegStar /></div>; // Estrella vacía
        }
    });

    return <div className="d-flex">{stars}</div>;
};
