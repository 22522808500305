import React from 'react'

function MedicinaClinica() {
    return (
        <div className="container">
            <div className="" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-end align-items-end">

                    <div className="col bg-clinica"></div>
                    <div className="col">
                        <div className="text-cafe text-big text-bolder mt-5" style={{ lineHeight: '1' }}>Medicina Dermatológica Clínica</div>
                        <div className="text-rosa text-medium my-3">Brindamos atención integral para condiciones de la piel, cabello, uñas y mucosas. Mejorando la calidad de vida de nuestros pacientes.</div>

                        <div className="text-rosa text-between">Ejemplos de Padecimientos: </div>
                        <div className="card-shadow text-rosa p-3 mt-2"><span className="text-bolder">Ezcema o Piel Seca.</span></div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Dermatitis atópica.</span></div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Acné.</span></div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Manchas (melasma).</span></div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Hongo en uñas (onicomicosis).</span></div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Caída del cabello.</span></div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Entre otros.</span></div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default MedicinaClinica