


function Medicina() {
    return (
        <div className="container">
            <div className="" style={{marginTop:'-450px'}}>
                <div className="d-flex justify-content-end align-items-end">
                    <div className="col">
                        <div className="text-rosa text-medium">En nuestra sección de Dermatología, ofrecemos una variedad de servicios especializados para el cuidado de la piel. A continuación, encontrarás detalles sobre cada uno de nuestros servicios.</div>
                        <div className="text-cafe text-big text-bolder mt-5" style={{lineHeight:'1'}}>Medicina Dermatológica Estética</div>
                        <div className="text-rosa text-medium my-3">La Medicina Dermatológica Estética se centra en tratamientos no quirúrgicos para mejorar la apariencia de la piel. Esto incluye el uso de rellenos dérmicos, Botox y tratamientos láser que ayudan a rejuvenecer la piel y tratar diversas imperfecciones.</div>
                        <div className="text-rosa text-between">Ejemplos de Tratamientos:</div>
                        <div className="card-shadow text-rosa p-3 mt-2"><span className="text-bolder">Bioestimuladores: </span>se utilizan para rejuveneces el rostro y otras áreas del cuerpo, promoviendo una apariencia mas fresca y saludable.</div>
                        <div className="card-shadow text-rosa p-3 mt-2"><span className="text-bolder">Äcido Hialurónico:  </span>hidrata profundamente la piel al retener grandes cantidades de agua mejorando su elasticidad y suavidad</div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Toxina Botulínica: </span>reduce las arrugas y las líneas de expresión.</div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Laser CO2 Fraccionado: </span>estimula la producción de colágeno y elastina, mejorando la firmeza y textura de la piel. Es eficaz para reducir cicatrices, arrugas, manchas y poros dilatados, ofreciendo un efecto rejuvenecedor. Además, mejora la apariencia general de la piel con resultados duraderos y una recuperación relativamente rápida.</div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Depilación Láser Diodo: </span>ofrece una eliminación duradera del vello, reduciendo su crecimiento de forma eficaz y segura. Es apta para diferentes tipos de piel y áreas del cuerpo, proporcionando tratamientos rápidos y menos dolorosos en comparación con otros métodos. Además, ayuda a prevenir problemas como foliculitis y pelos encarnados, dejando la piel suave y uniforme.</div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Hydrafacial: </span> limpia profundamente los poros, eliminando impurezas y células muertas mientras hidrata intensamente la piel. Mejora la textura, luminosidad y elasticidad, reduciendo signos de envejecimiento, manchas y acné. Es un tratamiento no invasivo, rápido y adecuado para todo tipo de piel, con resultados visibles desde la primera sesión.</div>
                    </div>
                    <div className="col bg-medicina ms-5"></div>

                </div>
            </div>
        </div>
    )
}

export default Medicina