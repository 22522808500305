import favoritosPeroli from '../../../assets/images/favoritos-peroli.png'
import logoPeroli from '../../../assets/images/peroli_logo.png'


const Favoritoperoli = () => {
    return (
        <div className="bg-gradiente-rosa" style={{height:'250px', marginTop:'200px'}}>
            <div className='container'>
                <div className="d-flex" >
                    <div><img className='img-favoritos' src={favoritosPeroli} alt="skin" /></div>
                    <div className='w-50 ms-5'>
                        <div><img className='logo-favoritos' src={logoPeroli} alt="LogoPeroli" /></div>
                        <div className='text-cafe py-5'>Visítanos en Peroli y descubre cómo estos tratamientos pueden transformar la salud y belleza de tu piel. ¡Te esperamos!</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Favoritoperoli