import Contador from './sections/contador';
import Equipo from './sections/equipo';
import MisionVision from './sections/misionVision';
import NuestrosDoctores from './sections/nuestrosDoctores';
import TarjetasInfo from './sections/tarjetasInfo';

function Nosotros() {
  return (
    <>
      <Equipo/>
      <MisionVision/>
      <TarjetasInfo/>
      <NuestrosDoctores/>
      <Contador/>
    </>
  );
}

export default Nosotros;
