import doctorImage1 from '../../../assets/images/Doctores/Dr1.png'
import doctorImage2 from '../../../assets/images/Doctores/Dr2.png'
import doctorImage3 from '../../../assets/images/Doctores/Dr3.png'
// import doctorImage4 from '../../../assets/images/Doctores/Dr4.jpg'

function NuestrosDoctores() {
    return (
        <div className="">
            <div className="container mt-5">
                <div className="text-cafe text-between text-bolder">Conoce a Nuestro Equipo</div>
                <div className="text-rosa text-medium mt-3">Nuestro equipo de expertos está dedicado a ofrecer el mejor cuidado para tu piel. Cada doctor aporta una gran experiencia y un compromiso con la excelencia.</div>
                <div className="d-flex pt-5 pb-3">
                    <div className="card-shadow bg-white d-flex p-2 ms-3 w-50">
                        <div className="bg-gradiente-rosa">
                            <img style={{width:'200px'}} src={doctorImage1} alt="Doctor" />
                        </div>
                        <div className='p-3'>
                            <div className='text-cafe text-bold'>Dra. Mirta de Lourdes Peréz Olivos</div>
                            <div className='text-rosa my-3'>Dermatóloga</div>
                            <div className='text-rosa'>Egresada de Medicina General por la Universidad de Guadalajara, y especialidad en Dermatologia cursado en el Instituto Dermatológico "José Barba Rubio", adicionalmente cuenta el diplomado en Tricología por el Colegio Ibero Latinoamericano de Dermatología.</div>
                        </div>
                    </div>
                    <div className="card-shadow bg-white d-flex p-2 ms-3 w-50">
                        <div className="bg-gradiente-rosa">
                            <img style={{width:'200px'}} src={doctorImage2} alt="Doctor" />
                        </div>
                        <div className='p-3'>
                            <div className='text-cafe text-bold'>Lucero Martínez Ruiz</div>
                            <div className='text-rosa my-3'>Cosmetóloga </div>
                            <div className='text-rosa'>Con mas de 5 años de experiencia en el uso de aparatología, limpiezas faciales, hydrafacial y masajes corporales</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex pt-5 pb-3">
                    <div className="card-shadow bg-white d-flex p-2 ms-3 w-50">
                        <div className="bg-gradiente-rosa">
                            <img style={{width:'200px'}} src={doctorImage3} alt="Doctor" />
                        </div>
                        <div className='p-3'>
                            <div className='text-cafe text-bold'>Elizabeth Jiménez Morales</div>
                            <div className='text-rosa my-3'>Cosmetóloga </div>
                            <div className='text-rosa'>Con experiencia en la realización de faciales, masajes reductivos, depilación y uso de aparatología</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NuestrosDoctores