

function Equipo() {
  return (
    <div className="container">
        <div className="bg-nosotros mt-5">
          <div className="d-flex">
            <div className="col"></div>
            <div className="col">
              <div className="text-cafe text-bolder text-big pb-3" style={{ lineHeight: '1px' }}>Nuestro Equipo</div>
              <div className="text-cafe text-medium py-5">
                Confía el cuidado de tu piel a Peroli.
              </div>
              <div className="text-rosa">En nuestro centro dermatológico, nos apasiona ofrecer atención de calidad y soluciones innovadoras para el cuidado de la piel. Nuestro equipo está compuesto por expertos dedicados a brindarte los mejores resultados en cada tratamiento.</div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Equipo