import Banner from "./sections/banner"
import Productos from "./sections/productos"


function Farmacia() {
  return (
    <div>
        <Banner/>
        <Productos/>
    </div>
  )
}

export default Farmacia