import shopskin from '../../../assets/images/shopskin.png'
import banner from '../../../assets/images/banner.png'

function Banner() {
    return (
        <div className="bg-gradiente-rosalight">
            <div className="container">

                <div className="d-flex justify-content-center align-items-center">

                    <div className="col w-50">
                        <div className=''>
                            <div className="text-cafe text-big text-bolder mt-5">Peroli Shop</div>
                            <div className="text-rosa text-medium my-3">Productos especializados para el cuidado de tu piel.</div>
                            <div className="text-rosa">En nuestra tienda, encontrarás una selección exclusiva de productos dermatológicos diseñados para mantener tu piel saludable, radiante y protegida. Cada producto ha sido cuidadosamente elegido por nuestros expertos para ofrecerte calidad y resultados confiables. ¡Explora y encuentra lo mejor para tu piel!</div>
                        </div>
                    </div>
                    <div className="col w-50">
                        <img className='shopskin' src={shopskin} alt="skin" />
                    </div>
                </div>

            </div>
            <div>
                <img className='w-100' src={banner} alt="banner"  />
            </div>
        </div>
    )
}

export default Banner