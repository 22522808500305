import Favoritoperoli from "./sections/favoritoperoli"
import Favoritopiel from "./sections/favoritopiel"
import Servicios from "./sections/servicios"

const Favoritos = () => {
  return (
    <div>
      <Favoritopiel/>
      <Servicios/>
      <Favoritoperoli/>
    </div>
  )
}

export default Favoritos