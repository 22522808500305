import imagen_13 from '../../../assets/images/Cards/imagen_13.png'
import imagen_12 from '../../../assets/images/Cards/imagen_12.png'
import imagen_14 from '../../../assets/images/Cards/imagen_14.png'
import imagen_15 from '../../../assets/images/Cards/imagen_15.png'

function Servicios() {
    return (
        <div className="container">
            <div className="d-flex">
                <div className="card-shadow" style={{width:'30%'}}>
                    <div className='d-flex align-items-center' style={{height:'330px'}}><img className='w-100' src={imagen_13} alt="Hydrafacial"/></div>
                    <div className="text-cafe text-medium text-bold p-2">Hydrafacial</div>
                    <div className="text-cafe p-2">
                        Un tratamiento avanzado que limpia, hidrata y repone antioxidantes esenciales, promoviendo la restauración natural de tu piel.
                    </div>
                </div>
                <div className="card-shadow mx-4" style={{width:'30%'}}>
                    <div style={{height:'330px'}}><img className='w-100' src={imagen_12} alt="Hydrafacial" /></div>
                    <div className="text-cafe text-medium text-bold p-2">Nanopore</div>
                    <div className="text-cafe p-2">
                    Rejuvenece tu piel con la innovadora terapia de inducción de colágeno. Un método mínimamente invasivo que revitaliza tu rostro con microagujas de alta precisión.
                    </div>
                </div>
                <div className="card-shadow" style={{width:'30%'}}>
                    <div style={{height:'330px'}}><img className='w-100' src={imagen_14} alt="Hydrafacial" /></div>
                    <div className="text-cafe text-medium text-bold p-2">Depilación Láser Diodo</div>
                    <div className="text-cafe p-2">
                    Elimina el vello no deseado de manera eficaz y segura en cualquier área del cuerpo, incluyendo zonas sensibles como axilas, bikini y rostro
                    </div>
                </div>
                <div className="card-shadow ms-4" style={{width:'30%'}}>
                    <div style={{height:'330px'}}><img className='w-100' src={imagen_15} alt="Hydrafacial" /></div>
                    <div className="text-cafe text-medium text-bold p-2">Depilación Láser Diodo</div>
                    <div className="text-cafe p-2">
                    Elimina el vello no deseado de manera eficaz y segura en cualquier área del cuerpo, incluyendo zonas sensibles como axilas, bikini y rostro
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Servicios