import OverLayIU from "../../../components/overlay_ui";
import IconUI from "../../../components/icon_ui";
import { GrClose } from "react-icons/gr";

export default function MasInfoUsuario({ onclose, data }) {
 
  return (
    <>
      <OverLayIU
        onClick={() => {
          onclose();
        }}
      />
      <div className="wizard-max-1000">
        <div className="d-flex mb-3">
          <div className="col ">  </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              onclose();
            }}
          >
            <IconUI size={15}>
              <GrClose></GrClose>
            </IconUI>
          </div>
        </div>
        <div className="d-flex flex-column justify-conten-center align-items-center">
          <div className="text-cafe text-bold">Dirección del Usuario : {data.nombre}</div>

        </div>
        <div className="mt-3 text-rosa text-bold border-bottom">Calle : {data.calle}</div>
        <div className="mt-3 text-rosa text-bold border-bottom">Colonia : {data.colonia}</div>
        <div className="mt-3 text-rosa text-bold border-bottom">Numero Exterior : {data.numero}</div>
        <div className="mt-3 text-rosa text-bold border-bottom">Numero Interior: {data.numero_interior}</div>
        <div className="mt-3 text-rosa text-bold border-bottom">Código Postal: {data.codigo_postal}</div>
        <div className="mt-3 text-rosa text-bold border-bottom">Referencias : {data.referencias}</div>
        <div className="mt-3 text-rosa text-bold border-bottom">Estado : {data.estado}</div>
        <div className="my-3 text-rosa text-bold border-bottom">Ciudad : {data.ciudad}</div>
      </div>
    </>
  );
}
