import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { urlBackslash } from 'utils/urlEnpoint';

function Gracias() {
  const [ticketUrl, setTicketUrl] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const ticketUrlParam = queryParams.get('ticket_url');
    if (ticketUrlParam) {
      setTicketUrl(ticketUrlParam);
      localStorage.clear()
      localStorage.setItem("carrito", JSON.stringify([]))
    }
  }, []);
  const enviarWhatsApp = () => {
    if (ticketUrl) {
      const mensaje = `¡Gracias por tu compra! Si deseas seguir el proceso de tu compra, puedes ver tu ticket en PDF aquí: ${urlBackslash}${ticketUrl}`;
      const urlWhatsApp = `https://wa.me/3521118347/?text=${encodeURIComponent(mensaje)}`;
      window.open(urlWhatsApp, '_blank');
    } else {
      alert('No se ha generado el ticket.');
    }
  };
  return (
    <div className='container'>
      <h1 className='text-cafe my-3'>¡Gracias por tu compra!</h1>
      <div className='d-flex justify-content-end'>
        <div className='btn-rosa text-center my-3' style={{ width: '200px' }} onClick={() => {
          navigate("/")
        }}>IR AL INICIO</div>
      </div>
      {ticketUrl ? (
        <div className=''>
          <div className='d-flex justify-content-between'>
            <div>
              <p className='text-cafe'>Puedes ver o descargar tu ticket de compra en el siguiente enlace</p>
              <a className='text-rosa' style={{ textDecoration: 'underline' }} href={`${urlBackslash}${ticketUrl}`} target="_blank" rel="noopener noreferrer">
                Descargar Ticket
              </a>
            </div>
            <div>

              <button className='btn-rosaBorde ms-3' style={{ width: '200px' }} onClick={enviarWhatsApp}>Enviar por WhatsApp</button>
            </div>
          </div>

          <br />
          <iframe
            src={`${urlBackslash}${ticketUrl}`}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
            title="Ticket de compra"
          />

        </div>

      ) : (
        <p>No se ha encontrado el ticket.</p>
      )}
    </div>
  )
}

export default Gracias