import React, { useState } from "react";
import { IoCloseCircle, IoLogoWhatsapp, IoArrowUpCircleOutline } from "react-icons/io5";
import { FaCirclePlus } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import "./BotonFlotante.css";
import IconUI from "components/icon_ui";

export default function BotonFlotante() {
  const [fabOpened, setFabOpened] = useState(false);

  const toggleFab = () => {
    setFabOpened(!fabOpened);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={`fab-container ${fabOpened ? "fab-opened" : ""}`}>
      <button className="fab-button" onClick={toggleFab}>
        {fabOpened ? <IoCloseCircle /> : <FaCirclePlus />}
      </button>

      {fabOpened && (
        <div className="fab-actions">
          <a
            href="https://wa.me/3521118347"
            target="_blank"
            rel="noopener noreferrer"
            className="fab-action"
            title="WhatsApp"
          >
            <IconUI color={'white'}>
              <IoLogoWhatsapp />
            </IconUI>
          </a>
          <a
            href="https://maps.app.goo.gl/DK5bmkXS5gCNzQkd9"
            target="_blank"
            rel="noopener noreferrer"
            className="fab-action"
            title="Ubicación"
          >
            <IconUI color={'white'}>
              <FaMapMarkedAlt />
            </IconUI>
          </a>
          <button className="fab-action" onClick={scrollToTop} title="Volver arriba">
            <IconUI size={'25px'} color={'white'}>
              <IoArrowUpCircleOutline />
            </IconUI>
          </button>
        </div>
      )}
    </div>
  );
}
