import HeaderHome from "components/headerHome"
import MenuLateral from "components/menuLateral"
import UsuariosCrud from "./sections/usuariosCrud"


function AdminUsuarios() {

  return (
    <div className="bg-gray">
      <div className="d-flex">
        <MenuLateral />
        <div className="mx-5 w-100">
          <HeaderHome/>
          <UsuariosCrud/>
        </div>
      </div>
    </div>
  )
}

export default AdminUsuarios