

function Consulta() {
    return (
        <div className="container">
            <div className="" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-end">
                    <div className="col bg-consulta"></div>
                    <div className="col">
                        <div className="text-cafe text-big text-bolder" style={{ lineHeight: '1' }}>Consulta Dermatológica</div>
                        <div className="text-rosa text-medium my-3">Consultas personalizadas para diagnosticar y tratar afecciones de la piel, cabello, uñas o mucosas. Durante la consulta se evalúa la historia clínica y se realiza un examen físico para determinar el tratamiento adecuado.</div>

                        <div className="text-rosa text-between">Ejemplos de Tratamientos:</div>
                        <div className="card-shadow text-rosa p-3 mt-2"><span className="text-bolder">Evaluación de Lesiones: </span>Revisiones de lunares y manchas sospechosas.</div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Historial Médico Completo: </span>Análisis de antecedentes familiares y/o padecimientos actuales.</div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Exploración Física completa</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consulta