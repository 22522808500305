import HeaderHome from "components/headerHome"
import MenuLateralUsuario from "components/menuLateralUsuario"
import Compras from "./sections/compras"



function UsuarioCompras() {
  return (
    <div className="bg-gray">
      <div className="d-flex">
        <MenuLateralUsuario />
        <div className="mx-5 w-100">
          <HeaderHome/>
          <Compras/>
        </div>
      </div>
    </div>
  )
}

export default UsuarioCompras