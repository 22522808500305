import logoPeroli from '../assets/images/peroli_logo.png'
import { PiUserSquareFill } from "react-icons/pi";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { BsCashCoin } from "react-icons/bs";
import { LiaComments } from "react-icons/lia";
import { MdKeyboardArrowRight } from "react-icons/md";
import IconUI from './icon_ui';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function MenuLateral() {
    const navigate = useNavigate()
    const [path, setpath] = useState('')
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
          let path = window.location.pathname
          setpath(path)
    
        }
    
        return () => {
          isMounted = false
        }
        // eslint-disable-next-line 
      }, [window.location.pathname])
    return (
        <>

            <div className='Menu_lateral'>
                <div className='text-center'>
                    <img src={logoPeroli} alt="Logo Peroli" />
                </div>
                <div className='mt-4'>
                    <div className={path === "/admin-usuarios"? 'p-3 my-2 d-flex opciones-menu-active':'p-3 my-2 d-flex opciones-menu'} onClick={()=>{
                        navigate("/admin-usuarios")
                    }}>
                        <div className='col'>
                            <div className='d-flex align-items-center'>
                                <div className='me-3'>
                                    <IconUI size={'30px'}>
                                        <PiUserSquareFill />
                                    </IconUI>
                                </div>
                                <div>
                                    Usuarios
                                </div>
                            </div>
                        </div>
                        <div>
                            <IconUI size={'30px'}>
                                <MdKeyboardArrowRight />
                            </IconUI>
                        </div>
                    </div>
                    <div className={path === "/admin-productos"? 'p-3 my-2 d-flex opciones-menu-active':'p-3 my-2 d-flex opciones-menu'} onClick={()=>{
                        navigate("/admin-productos")
                    }}>
                        <div className='col align-items-center'>
                            <div className='d-flex'>
                                <div className='me-3'>
                                    <IconUI size={'30px'}>
                                        <LiaShoppingBagSolid />
                                    </IconUI>
                                </div>
                                <div>
                                    Productos
                                </div>
                            </div>
                        </div>
                        <div>
                            <IconUI size={'30px'}>
                                <MdKeyboardArrowRight />
                            </IconUI>
                        </div>
                    </div>
                    <div className={path === "/admin-ventas"? 'p-3 my-2 d-flex opciones-menu-active':'p-3 my-2 d-flex opciones-menu'} onClick={()=>{
                        navigate("/admin-ventas")
                    }}>
                        <div className='col'>
                            <div className="d-flex align-items-center">
                                <div className='me-3'>
                                    <IconUI size={'30px'}>
                                        <BsCashCoin />
                                    </IconUI>
                                </div>
                                <div>
                                    Ventas
                                </div>
                            </div>
                        </div>
                        <div>
                            <IconUI size={'30px'}>
                                <MdKeyboardArrowRight />
                            </IconUI>
                        </div>
                    </div>
                    <div className={path === "/admin-comentarios"? 'p-3 my-2 d-flex opciones-menu-active':'p-3 my-2 d-flex opciones-menu'} onClick={()=>{
                        navigate("/admin-comentarios")
                    }}>
                        <div className='col'>
                            <div className="d-flex align-items-center">
                                <div className='me-3'>
                                    <IconUI size={'30px'}>
                                        <LiaComments />
                                    </IconUI>
                                </div>
                                <div>
                                    Comentarios
                                </div>
                            </div>
                        </div>
                        <div>
                            <IconUI size={'30px'}>
                                <MdKeyboardArrowRight />
                            </IconUI>
                        </div>
                    </div>

                </div>

            </div>
        </>

    )
}

export default MenuLateral