import OverLayIU from "../../../components/overlay_ui";
import IconUI from "../../../components/icon_ui";
import { GrClose } from "react-icons/gr";
import { urlBack } from "utils/urlEnpoint";

export default function EliminarProducto({ onclose, data }) {


    return (
        <>
            <OverLayIU
                onClick={() => {
                    onclose();
                }}
            />
            <div className="wizard-max-1000">
                <div className="d-flex mb-3">
                    <div className="col ">  </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            onclose();
                        }}
                    >
                        <IconUI size={15}>
                            <GrClose></GrClose>
                        </IconUI>
                    </div>
                </div>
                <div className="d-flex flex-column justify-conten-center align-items-center">
                    <div className="text-cafe text-bold">¿ Estas seguro de Eliminar al Usuario ?</div>

                </div>
                <div className="mt-3 text-rosa text-bold border-bottom">Nombre : {data.nombre}</div>
                <div className="mt-3 text-rosa text-bold border-bottom">Imagenes : </div>
                {data.imagenes.map((img, i) => (
                    <div className="col my-3 d-flex align-items-center justify-conten-center" key={i} >
                        <div>
                            <img
                                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                src={`${urlBack}${img}`}
                                alt="Producto"
                            />
                        </div>
                   
                    </div>
                ))}
                <div className="d-flex my-4 justify-content-center align-items-center">
                    <div className="me-3 btn-rosaBorde text-center" style={{ width: '200px' }} onClick={() => {
                        onclose();
                    }}>Cancelar</div>
                    <div className="btn-rosa text-center" style={{ width: '200px' }}
                        onClick={() => {
                            onclose(true);
                        }}
                    >Aceptar</div>
                </div>

            </div>
        </>
    );
}
