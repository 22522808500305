import { useState } from 'react';
import vector from '../../../assets/images/Vector1.png';

function Contacto() {
  const [state, setState] = useState({
    name: '',
    email: '',
    description: '',
  });
  const [error, setError] = useState('');

  const handleChangeName = (text) => {
    setState({ ...state, name: text });
  };

  const handleChangeEmail = (text) => {
    setState({ ...state, email: text });
  };

  const handleChangeDescription = (text) => {
    setState({ ...state, description: text });
  };

  const resetForm = () => {
    setState({
      name: '',
      email: '',
      description: '',
    });
    setError('');
  };

  return (
    <div className="container">
      <div className="">
        <div className="d-flex justify-content-end align-items-start">
          <div className="col">
            <div className="text-cafe text-big text-bolder mt-5">Contáctanos</div>
            <div className="text-rosa text-medium my-3">
              Conéctate con Peroli, estamos aquí para ti.
            </div>
            <div className="text-rosa">
              ¿Tienes preguntas o buscas colaborar con nosotros? Completa nuestro formulario de contacto y nuestro equipo estará encantado de ayudarte. ¡Estamos aquí para ti!
            </div>
            <div className="py-5">
              <img src={vector} alt="vector" />
            </div>
          </div>
          <div className="col " style={{ marginTop: '100px' }}>
            <div className="card-shadow p-3">
              <div className="text-gray text-bold">Nombre Completo</div>
              <div className="my-2 ">
                <input
                  placeholder="Nombre"
                  className="input-text"
                  type="text"
                  value={state.name}
                  onChange={(evt) => {
                    handleChangeName(evt.target.value);
                  }}
                />
              </div>
              <div className="text-gray text-bold my-3">Correo Electrónico</div>
              <div className="my-2 ">
                <input
                  placeholder="Correo"
                  className="input-text"
                  type="email"
                  value={state.email}
                  onChange={(evt) => {
                    handleChangeEmail(evt.target.value);
                  }}
                />
              </div>
              <div className="text-gray text-bold my-3">¿Cómo podemos ayudarte?</div>
              <div className="">
                <textarea
                  className="input-area"
                  placeholder="Describe tu consulta en menos de 250 caracteres."
                  value={state.description}
                  onChange={(evt) => {
                    handleChangeDescription(evt.target.value);
                  }}
                />
              </div>
              {error && <div className="text-danger my-2">{error}</div>}
              <MailtoButton
                nombre={state.name}
                correo={state.email}
                descripcion={state.description}
                setError={setError}
                resetForm={resetForm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacto;

const MailtoButton = ({ nombre, correo, descripcion, setError, resetForm }) => {
  const handleClick = () => {
    // Validación
    if (!nombre || !correo || !descripcion) {
      setError('Favor de llenar todos los campos.');
      return;
    }

    // Construir dinámicamente el enlace mailto
    const mailtoHref = `mailto:dermaperoli@gmail.com?subject=Consulta de ${encodeURIComponent(
      nombre
    )}&body=Nombre completo: ${encodeURIComponent(
      nombre
    )}%0ACorreo electrónico: ${encodeURIComponent(
      correo
    )}%0ADescripción: ${encodeURIComponent(descripcion)}`;

    // Abrir el enlace mailto en una nueva pestaña
    window.location.href = mailtoHref;

    // Limpia el formulario después de enviar
    setError('');
    resetForm();
  };

  return (
    <button
      className="btn-ref"
      style={{ textDecoration: 'none', color: 'blue' }}
      onClick={handleClick}
    >
      Enviar Mensaje
    </button>
  );
};
