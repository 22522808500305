import { useState } from "react";
import { useEffect } from "react";
import { urlBack } from "utils/urlEnpoint";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ProductosContext } from "context/productos_context";
import { NotificacionContext } from "context/notificacion_context";

function Productos() {
    const {productosState,setProductosState} = useContext(ProductosContext)
    const {showNotification} = useContext(NotificacionContext)
    const [productos, setproductos] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        let isMounted = true;

        const fetchProductos = async () => {
            const productos = await getProductos(`${urlBack}productos_publico.php`);
            if (isMounted && productos) {
                setproductos(productos)
            }
        };

        fetchProductos();

        return () => {
            isMounted = false;
        };
    }, []);

    const getProductos = async (url) => {
        try {
            const resp = await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
                mode: "cors",
            });

            if (!resp.ok) {
                throw new Error(`Error HTTP! status: ${resp.status}`);
            }

            const json = await resp.json();
            return json;
        } catch (error) {
            console.error("Error al obtener los productos:", error);
            return { error: error.message };
        }
    };

    const handleSelectProduct = (producto) => {
        setProductosState({...productosState, producto})
        navigate(`/detalleProducto/${producto.idProducto}`)
    }
    const handleAddStore = (producto) => {
        const carritoPush = productosState.carrito
        carritoPush.push(producto)
        setProductosState({...productosState, carrito:carritoPush})

        localStorage.setItem("carrito",JSON.stringify(carritoPush))

        showNotification(
            "success",
            "Se añadio producto al carrito",
            "La operación se realizó correctamente",
            null,
            5
          );
    }
    return (
        <div className="container">
            <div className="row m-auto">
                {productos.map((producto, i) => {
                    return (
                        <div className="col my-5" align="center" key={i}>

                            <div className="card-shadow" style={{ width: '250px' }} >
                                <img width={'100%'} height={'320px'} src={`${urlBack}${producto.imagenes[0]}`} alt={`${producto.imagenes[0]}`} />
                                <div className="p-3 " style={{ textAlign: 'left' }}>
                                    <div className="text-bold">{producto.nombreProducto}</div>
                                    {/* <div className="text-cafe my-3">{producto.descripcion}</div> */}
                                    <div className="mt-3" style={{textDecoration:'line-through'}}>$ {producto.precio} MXN</div>
                                    <div className="mb-3 text-medium text-bolder">$ {producto.monto_descuento} MXN</div>
                                    <div className="text-rosa cursor-pointer mb-3" onClick={() => {
                                        handleSelectProduct(producto)
                                    }}>Ver detalle</div>
                                    <div className=" d-flex">
                                        <div className="cta cta--rosa" onClick={() => {
                                            handleAddStore(producto)
                                        }}>
                                            <div><LocalGroceryStoreIcon /></div>
                                            <div className="text-small ms-2 text-center">Agregar al Carrito</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default Productos;
