import IconUI from 'components/icon_ui';
import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { urlBack } from 'utils/urlEnpoint';
import { IoEyeOutline } from "react-icons/io5";
import { NotificacionContext } from 'context/notificacion_context';
import MUIDataTable from "mui-datatables";
import ComentariosProducto from './comentariosProducto';

function Comentarios() {

    const [comentarios, setComentarios] = useState({})
    const navigate = useNavigate()

    const [productoSeleccionado, setProductoSeleccionado] = useState({})
    const [oopupComentarios, setPopupComentarios] = useState(false)
    const { showNotification } = useContext(NotificacionContext)


    const getVentas = async (url, token) => {
        try {
            const resp = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                mode: "cors",
                "Authorization": `Bearer ${token}`
            });

            if (!resp.ok) {
                throw new Error(`Error HTTP! status: ${resp.status}`);
            }

            const json = await resp.json();
            return json;
        } catch (error) {
            console.error("Error al obtener los productos:", error);
            return { error: error.message };
        }
    }
    const fetchComentarios = async () => {
        const token = localStorage.getItem("token")
        const comentarios = await getVentas(`${urlBack}comentarios.php`, token);

        if (comentarios) {
            if (comentarios.error === 'Error HTTP! status: 401') {
                localStorage.removeItem("token")
                localStorage.removeItem("nombre")
                localStorage.removeItem("tipoUsuario")
                showNotification(
                    "warning",
                    "Se caducó la Sesión",
                    "vuelve a Ingresar de nuevo",
                    null,
                    5
                );
                navigate("/login")
            } else {
                setComentarios(comentarios.data)
            }
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            fetchComentarios();
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line 
    }, []);

    const columns = [
        {
            name: "imagenes",
            label: "Producto",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = comentarios[dataIndex]?.imagenes[0];
                    console.log(imagen)
                    return (
                        <div >
                            <img style={{ width: '70px', height: '70px' }} src={`${urlBack}${imagen}`} alt="producto" />
                        </div>
                    );
                },
            },
        },
        {
            name: "nombreProducto",
            label: "Nombre",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombreProducto = comentarios[dataIndex]?.nombreProducto;
                    return (
                        <div>
                            {nombreProducto}
                        </div>
                    );
                },
            },
        },

        {
            name: "Ver comentarios",
            label: "Acciones",

            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => {
                    return (
                        <>
                            <div className="d-flex">
                                <div className="btn-acciones-out" onClick={() => {
                                    setPopupComentarios(true)
                                    setProductoSeleccionado(comentarios[dataIndex])
                                }}>
                                    <IconUI size={'15px'}>
                                        <IoEyeOutline />
                                    </IconUI>
                                </div>
                            </div>
                        </>
                    );
                },
            },
        },
    ];
    const options = {
        selectableRows: "none",
        tableBodyHeight: "auto",
        textLabels: {
            body: {
                // noMatch: isLoading ? <Loader /> : "Lo sentimos, no hay coincidencias en la busqueda",
                noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todo",
                title: "Filtros",
                reset: "Restaurar",
            },
            viewColumns: {
                title: "Ver columnas",
                titleAria: "Mostrar/Ocultar columnas en la tabla",
            },
            selectedRows: {
                text: "columna(s) selecciona(as)",
                delete: "Eliminar",
                deleteAria: "Eliminar filas seleccionadas",
            },
        },
        responsive: "standard",
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
    };

    return (
        <div className='mt-3 bg-home'>
            <div className='text-cafe text-medium'>Comentarios</div>

            <div className="mt-4">
                {comentarios.length > 0 ?
                    <MUIDataTable
                        style={{ Overflow: "hidden" }}
                        title={"Registros"}
                        data={comentarios}
                        columns={columns}
                        options={options}
                    // onDownload={false}
                    />
                    :
                    null
                }
                {oopupComentarios ?
                    <ComentariosProducto data={productoSeleccionado} onclose={(bandera) => {
                        setPopupComentarios(false)
                        if(bandera){
                            fetchComentarios()
                        }
                    }} />
                    :
                    null
                }

            </div>

        </div>
    )
}

export default Comentarios