import React, { useState } from "react";
import OverLayIU from "../components/overlay_ui";

import IconUI from "../components/icon_ui";
import { GrClose } from "react-icons/gr";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { urlBack } from "utils/urlEnpoint";
import { useNavigate } from "react-router-dom";

// function createMarkup(text) {
//   return { __html: text };
// }
const enviarData = async (url, data) => {
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    });

    if (!resp.ok) {
      const errorText = await resp.text();
      throw new Error(`Error en la respuesta del servidor: ${resp.status} ${errorText}`);
    }

    return await resp.json();
  } catch (error) {
    console.error("Error al enviar los datos:", error);
    return { error: error.message };
  }
};

export default function PopUp({ onclose, carrito }) {
  let navigate = useNavigate()
  const [sesion, setSesion] = useState({
    correo: '',
    Password: '',
    show: false
  })
  const [invite, setInvite] = useState(false)
  const [espera, setEspera] = useState(false)
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [usuario, setUsuario] = useState({
    idTipoUsuario: "3",
    celular: "",
    calle: "",
    colonia: "",
    numero: "",
    numero_interior: "",
    codigo_postal: "",
    estado: "",
    ciudad: "",
    referencias: ""
  })

  const handleShowSesion = () => {
    setSesion({ ...sesion, show: true })
  }
  const handleCloseSesion = () => {
    setSesion({ ...sesion, show: false })
  }
  const handleChangeEmail = (text) => {
    setSesion({ ...sesion, correo: text })
  }

  const handleChangeContraseña = (text) => {
    setSesion({ ...sesion, Password: text })
  }

  const handleLogin = async () => {
    setEspera(true);
    const data = {
      usuario: sesion.correo,
      clave: sesion.Password,
    };
    const respJson = await enviarData(`${urlBack}login.php`, data);
    if (respJson.data?.conectado) {
    
      const form = document.createElement("form");
      form.action = `${urlBack}checkout.php`;
      form.method = "POST";

      // Agregar el correo como input oculto
      const emailInput = document.createElement("input");
      emailInput.type = "hidden";
      emailInput.name = "usuario";
      emailInput.value = sesion.correo;
      form.appendChild(emailInput);



      carrito.forEach((producto, i) => {
        const inputs = [
          { name: `productos[${i}][nombre]`, value: producto.nombreProducto },
          { name: `productos[${i}][descripcion]`, value: producto.descripcion },
          { name: `productos[${i}][precio]`, value: producto.monto_descuento * 100 },
          { name: `productos[${i}][cantidad]`, value: producto.cantidad },
          { name: `productos[${i}][url_imagen]`, value: `${urlBack}${producto.imagenes[0]}` },
        ];

        inputs.forEach(({ name, value }) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = name;
          input.value = value;
          form.appendChild(input);
        });
      });

      document.body.appendChild(form);
      form.submit();
    } else {
      setError(respJson.error);
    }
    setEspera(false);
  };

  const validateFields = () => {
    // Verificar que el correo sea válido
    if (!sesion.correo || !/\S+@\S+\.\S+/.test(sesion.correo)) {
      setError("Por favor, ingresa un correo válido.");
      return false;
    }

    // Validar los campos obligatorios del domicilio
    const requiredFields = {
      "Código Postal": usuario.codigo_postal,
      "Celular": usuario.celular,
      "Calle": usuario.calle,
      "Colonia": usuario.colonia,
      "Número Exterior": usuario.numero,
      "Estado": usuario.estado,
      "Ciudad": usuario.ciudad,
      "Referencias": usuario.referencias,
    };

    for (const [fieldName, value] of Object.entries(requiredFields)) {
      if (!value || value.trim() === "") {
        setError(`Por favor, completa el campo: ${fieldName}.`);
        return false;
      }
    }

    // Si todo está correcto, limpiar errores
    setError("");
    return true;
  };

  const handleGoToStripe = async () => {
    setEspera(true);
    if (validateFields()) {

      const form = document.createElement("form");
      form.action = `${urlBack}checkout.php`;
      form.method = "POST";

      Object.keys(usuario).forEach((key) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = usuario[key];
        form.appendChild(input);
      });
      const emailInput = document.createElement("input");
      emailInput.type = "hidden";
      emailInput.name = "usuario";
      emailInput.value = sesion.correo;
      form.appendChild(emailInput);

      carrito.forEach((producto, i) => {
        const inputs = [
          { name: `productos[${i}][nombre]`, value: producto.nombreProducto },
          { name: `productos[${i}][descripcion]`, value: producto.descripcion },
          { name: `productos[${i}][precio]`, value: producto.monto_descuento * 100 },
          { name: `productos[${i}][cantidad]`, value: producto.cantidad },
          { name: `productos[${i}][url_imagen]`, value: `${urlBack}${producto.imagenes[0]}` },
        ];

        inputs.forEach(({ name, value }) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = name;
          input.value = value;
          form.appendChild(input);
        });
      });

      document.body.appendChild(form);
      form.submit();
    }


    setEspera(false);
  }
  const handleChangePhone = (text) => {
    setUsuario({ ...usuario, celular: text })
  }
  const handleChangeStreet = (text) => {
    setUsuario({ ...usuario, calle: text })
  }
  const handleChangeColonia = (text) => {
    setUsuario({ ...usuario, colonia: text })
  }
  const handleChangeNumber = (text) => {
    setUsuario({ ...usuario, numero: text })
  }
  const handleChangeNumberInt = (text) => {
    setUsuario({ ...usuario, numero_interior: text })
  }
  const handleChangeZipCode = (text) => {
    setUsuario({ ...usuario, codigo_postal: text })
  }
  const handleChangeState = (text) => {
    setUsuario({ ...usuario, estado: text })
  }
  const handleChangeCity = (text) => {
    setUsuario({ ...usuario, ciudad: text })
  }
  const handleChangeReferences = (text) => {
    setUsuario({ ...usuario, referencias: text })
  }


  return (
    <>
      <OverLayIU
        onClick={() => {
          onclose();
        }}
      />
      <div className="wizard">
        <div className="d-flex mb-3">
          <div className="col ">  </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              onclose();
            }}
          >
            <IconUI size={15}>
              <GrClose></GrClose>
            </IconUI>
          </div>
        </div>
        {sesion.show ?
          <div className="m-auto" style={{ width: '70%' }}>
            <div className="text-cafe text-bold btn-add d-flex justify-content-center align-items-center" onClick={() => { handleCloseSesion() }}>{`<`}</div>
            <div className="mt-3">
              <div className="my-3 text-gray text-bold">Correo :</div>
              <div className="d-flex">
                <input
                  type="email"
                  className="input-text"
                  placeholder="Ingrese su correo"
                  value={sesion.email}
                  onChange={(evt) => {
                    handleChangeEmail(evt.target.value);
                  }}
                />
                <div style={{ width: '30px', height: '30px' }}></div>
              </div>
              <div className="my-3  text-gray text-bold">Contraseña :</div>
              <div className="d-flex">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input-text"
                  placeholder="Ingrese su contraseña"
                  value={sesion.Password}
                  onChange={(evt) => {
                    handleChangeContraseña(evt.target.value);
                  }}
                />
                <div className="text-cafe text-medium p-2 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => {
                  setShowPassword(!showPassword)
                }}>
                  {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </div>
              </div>
              {espera ?

                <div className="mt-5 d-flex justify-content-center">
                  <div className="loader"></div>
                </div>
                :
                <div className="d-flex flex-column justify-conten-center align-items-center">
                  <div className="text-gray mt-3 btn-rosa text-center" onClick={() => {
                    handleLogin()
                  }}>Iniciar sesión</div>
                </div>
              }
            </div>
            <div className="text-danger mt-2 text-center" style={{ height: '30px' }}>{error ? error : ''}</div>
          </div>
          :
          <>
            {invite ?
              <div >
                <div className="text-cafe text-bold btn-add d-flex justify-content-center align-items-center" onClick={() => { setInvite(false) }}>{`<`}</div>
                <div className="d-flex flex-column justify-conten-center align-items-center" >
                  <div className="my-3 text-gray text-bold">Ingresa un Correo :</div>
                  <div className="d-flex" style={{ width: '60%' }}>
                    <input
                      type="email"
                      className="input-text"
                      placeholder="Ingrese su correo"
                      value={sesion.email}
                      onChange={(evt) => {
                        handleChangeEmail(evt.target.value);
                      }}
                    />
                  </div>
                  <div className="text-cafe text-bold mt-3">Ingresa los datos de tú domicilio:</div>
                  <div className="d-flex">
                    <div className="mt-3 mx-3">
                      <div className="my-3 text-gray text-bold">Código Postal :</div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Código Postal"
                          value={usuario.codigo_postal}
                          onChange={(evt) => {
                            handleChangeZipCode(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="my-3 text-gray text-bold">Celular :</div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Celular"
                          value={usuario.celular}
                          onChange={(evt) => {
                            handleChangePhone(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="my-3 text-gray text-bold">Calle :</div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Calle"
                          value={usuario.calle}
                          onChange={(evt) => {
                            handleChangeStreet(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="my-3 text-gray text-bold">Colonia :</div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Colonia"
                          value={usuario.colonia}
                          onChange={(evt) => {
                            handleChangeColonia(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="my-3 text-gray text-bold">Numero Exterior :</div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Numero"
                          value={usuario.numero}
                          onChange={(evt) => {
                            handleChangeNumber(evt.target.value);
                          }}
                        />
                      </div>



                    </div>
                    <div className="mt-3">
                      <div className="my-3 text-gray text-bold">Numero Interior :</div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Numero Interior"
                          value={usuario.numero_interior}
                          onChange={(evt) => {
                            handleChangeNumberInt(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="my-3 text-gray text-bold">Estado :</div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Estado"
                          value={usuario.estado}
                          onChange={(evt) => {
                            handleChangeState(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="my-3 text-gray text-bold">Ciudad :</div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Ciudad"
                          value={usuario.ciudad}
                          onChange={(evt) => {
                            handleChangeCity(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="my-3 text-gray text-bold">Referencias :</div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Referencias"
                          value={usuario.referencias}
                          onChange={(evt) => {
                            handleChangeReferences(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {espera ?

                  <div className="mt-5 d-flex justify-content-center">
                    <div className="loader"></div>
                  </div>
                  :
                  <div className="d-flex flex-column justify-conten-center align-items-center">
                    <div className="text-gray mt-3 btn-rosa text-center" onClick={() => {
                      handleGoToStripe()
                    }}>Continuar</div>
                    <div className="text-danger mt-2 text-center" style={{ height: '30px' }}>{error ? error : ''}</div>
                  </div>

                }
              </div>
              :
              <div className="d-flex flex-column justify-conten-center align-items-center">
                <div className="text-cafe text-bold">Carrito de Compra listo</div>
                <div className="text-gray mt-3">Para continuar con el proceso de pago accede a tu cuenta:</div>
                <div className="text-gray mt-3 btn-rosa text-center" onClick={() => {
                  handleShowSesion()
                }}>Iniciar sesión</div>
                <div className="text-gray mt-3 text-cafe text-center">¿No tienes cuenta?
                  <span className="ms-2 cursor-pointer" style={{ textDecoration: 'underline' }} onClick={() => {
                    navigate("/registro")
                  }}>Registrate</span>
                </div>
                <div className="text-gray mt-3">---------------------------------------- o ----------------------------------------</div>
                <div className="text-gray my-3 btn-rosaBorde text-center" onClick={() => {
                  setInvite(true)
                }}>Continuar como Invitado</div>

              </div>
            }

          </>
        }

      </div>
    </>
  );
}
