import skin from '../../../assets/images/skin.png'
import equipo from '../../../assets/images/equipo.png'
import tratamientos from '../../../assets/images/tratamientos.png'
import beauty from '../../../assets/images/beauty.png'


function Serviciosderma() {
    return (
        <div className="margin-negative-section2">
            <div className="container">
                <div className="text-cafe text-between text-bolder" style={{ lineHeight: '1' }}>Servicios Dermatológicos Integrales <br /> para el Cuidado de tu Piel</div>
                <div className="text-rosa text-medium mt-5">En Peroli, nos especializamos en brindarte soluciones completas para el cuidado de tu piel.</div>
                <div>
                    <div className="d-flex align-items-center justify-content-center my-5">
                        <div className="me-5 w-50">
                            <div className='d-flex' style={{ background: 'linear-gradient(90deg, rgba(233,216,198,1) 0%, rgba(227,191,157,1) 100%)', borderRadius: '50px' }}>
                                <div className='d-flex flex-column margin-negative-right-section2' style={{ paddingLeft: '5%', paddingTop: '20px', zIndex:'100'}}>
                                    <div className='text-cafe text-medium text-bolder'>Cuidado de la piel</div>
                                    <div className='text-cafe'>Productos dermatológicos de calidad para cada tipo de piel.</div>
                                </div>
                                <div style={{ marginTop: '-40px', paddingRight: '45px' }}>
                                    <img src={skin} alt="skin" />
                                </div>
                            </div>

                        </div>
                        <div className='w-50'>
                            <div className='d-flex' style={{ background: 'linear-gradient(90deg, rgba(220,202,196,1) 0%, rgba(187,149,149,1) 100%)', borderRadius: '50px' }}>
                                <div style={{ paddingLeft: '5%', paddingTop: '20px' }}>
                                    <div className='text-cafe text-medium text-bolder'>Equipo Profesional</div>
                                    <div className='text-cafe'>Un equipo de expertos siempre al cuidado de tu piel.</div>
                                </div>
                                <div style={{ marginTop: '-40px', paddingRight: '50px' }}>
                                    <img src={equipo} alt="skin" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className="d-flex align-items-center justify-content-center my-4">
                        <div className="me-5 w-50">
                            <div className='d-flex' style={{ background: 'linear-gradient(90deg, rgba(220,202,196,1) 0%, rgba(187,149,149,1) 100%)', borderRadius: '50px' }}>
                                <div className='d-flex flex-column' style={{ paddingLeft: '5%', paddingTop: '20px', zIndex:'100', marginRight:'-30px' }}>
                                    <div className='text-cafe text-medium text-bolder'>Tratamientos Avanzados</div>
                                    <div className='text-cafe'>Tecnología avanzada para el tratamiento de afecciones cutáneas.</div>
                                </div>
                                <div style={{ marginTop: '-40px'}}>
                                    <img src={tratamientos} alt="tratamientos" />
                                </div>
                            </div>

                        </div>
                        <div className='w-50 '>
                            <div className='d-flex' style={{ background: 'linear-gradient(90deg, rgba(233,216,198,1) 0%, rgba(227,191,157,1) 100%)', borderRadius: '50px' }}>
                                <div style={{ paddingLeft: '5%', paddingTop: '20px' }}>
                                    <div className='text-cafe text-medium text-bolder'>Resultados Garantizados</div>
                                    <div className='text-cafe'>Nuestra prioridad es mejorar la salud y el aspecto de tu piel.</div>
                                </div>
                                <div style={{ marginTop: '-70px', paddingRight: '50px' }}>
                                    <img src={beauty} alt="skin" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Serviciosderma