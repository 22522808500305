import OverLayIU from "../../../components/overlay_ui";
import IconUI from "../../../components/icon_ui";
import { GrClose } from "react-icons/gr";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { urlBack } from "utils/urlEnpoint";
import { useContext } from "react";
import { NotificacionContext } from "context/notificacion_context";


const enviarData = async (url, data, token) => {
    try {
        const resp = await fetch(url, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            mode: "cors",
        });

        if (!resp.ok) {
            throw new Error(`Error en la respuesta del servidor: ${resp.status}`);
        }

        return await resp.json();
    } catch (error) {
        console.error("Error al enviar los datos:", error);
        return { error: error.message };
    }
};

function ComentariosProducto({ data, onclose }) {
    const { showNotification } = useContext(NotificacionContext)
    const handleDeleteComment = async (id) => {
        let token = localStorage.getItem("token")
        const data = {
            accion: 'ocultar'
        }
        const respJson = await enviarData(`${urlBack}comentarios.php?id=${id}`, data, token);
        if (respJson.mensaje) {
            showNotification(
                "success",
                "Comentario Ocultado",
                "La operación se realizó correctamente",
                null,
                5
            );
            onclose(true)
        }else{
            showNotification(
                "error",
                "Comentario",
                respJson.error,
                null,
                5
            );
        }
    }
    const handleShowComment = async (id) => {
        let token = localStorage.getItem("token")
        const data = {
            accion: 'mostrar'
        }
        const respJson = await enviarData(`${urlBack}comentarios.php?id=${id}`, data, token);
        if (respJson.mensaje) {
            showNotification(
                "success",
                "Comentario Mostrado",
                "La operación se realizó correctamente",
                null,
                5
            );
            onclose(true)
        }else{
            showNotification(
                "error",
                "Comentario",
                respJson.error,
                null,
                5
            );
        }
    }


    return (
        <>
            <OverLayIU
                onClick={() => {
                    onclose();
                }}
            />
            <div className="wizard-max-1000">
                <div className="d-flex mb-3">
                    <div className="col ">  </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            onclose();
                        }}
                    >
                        <IconUI size={15}>
                            <GrClose></GrClose>
                        </IconUI>
                    </div>
                </div>
                <div className="d-flex flex-column justify-conten-center align-items-center">
                    <div className="text-cafe text-bold">Comentarios</div>

                </div>
                {data.comentarios.map((comentario, i) => {
                    console.log(comentario)
                    return (
                        <div className="border-bottom d-flex align-items-center" key={i}>
                            <div>
                                <div className="mt-3 text-rosa text-bold ">Nombre : {comentario.nombreCompleto}</div>
                                <div className="mt-3 text-rosa text-bold">Comentario : {comentario.comentario}</div>
                                <div className="mt-3 text-rosa text-bold">Fecha : {comentario.fecha}</div>
                                <div className="mt-3 text-rosa text-bold">Calificacion : {comentario.calificacion}</div>
                            </div>
                            {comentario.ocultar === "1" ?
                                <div>
                                    <div
                                        className="btn-add d-flex justify-content-center align-items-center ms-3"
                                        onClick={() => {
                                            handleShowComment(comentario.idComentario)
                                        }}
                                    >
                                        <IconUI size={15}>
                                            <MdVisibilityOff />
                                        </IconUI>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div
                                        className="btn-add d-flex justify-content-center align-items-center ms-3"
                                        onClick={() => {
                                           
                                            handleDeleteComment(comentario.idComentario)
                                        }}
                                    >
                                        <IconUI size={15}>
                                            <MdVisibility />
                                        </IconUI>
                                    </div>
                                </div>
                            }

                        </div>
                    )
                })}

                {/* <div className="mt-3 text-rosa text-bold border-bottom">Precio : {data.precio}</div>
                <div className="mt-3 text-rosa text-bold border-bottom">Stock : {data.stock}</div>
                <div className="mt-3 text-rosa text-bold border-bottom">Tipo de descuento: {data.tipo_descuento}</div>
                <div className="mt-3 text-rosa text-bold border-bottom">Precio total : {data.monto_descuento}</div> */}

            </div>
        </>
    )
}

export default ComentariosProducto