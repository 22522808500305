
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { urlBack } from "utils/urlEnpoint";

const enviarData = async (url, data) => {
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    });

    if (!resp.ok) {
      const errorText = await resp.text();
      throw new Error(`Error en la respuesta del servidor: ${resp.status} ${errorText}`);
    }

    return await resp.json();
  } catch (error) {
    console.error("Error al enviar los datos:", error);
    return { error: error.message };
  }
};

export default function Login() {
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [espera, setEspera] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: ''
  })

  const handleLogin = async () => {
    setEspera(true);

    const data = {
      usuario: state.email,
      clave: state.password,
    };
    if (state.email && state.password) {
      const respJson = await enviarData(`${urlBack}login.php`, data);

      if (respJson.data?.conectado) {
        localStorage.setItem("token", respJson.data.token);
        localStorage.setItem("tipoUsuario", respJson.data.etiquetaTipoUsuario);
        localStorage.setItem("nombre", respJson.data.nombre + ' ' + respJson.data.apellidos);
        if (respJson.data.etiquetaTipoUsuario === "Admin") {
          navigate("/admin-usuarios");
        } else {
          navigate("/mis-compras");
        }
      } else {
        setError(respJson.error);
        setEspera(false);
      }
    } else {
      setError('Favor de ingresar los datos.')
    }
    setEspera(false);
  };




  // Limpia el mensaje de error después de 5 segundos o si el usuario empieza a escribir
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);



  useEffect(() => {
    let isMounted = true;
    if (isMounted) {

      const token = localStorage.getItem("token")
      const tipoUsuario = localStorage.getItem("token")

      if (token) {
        if (tipoUsuario === "Admin") {
          navigate("/admin-usuarios")
        } else {
          navigate("/admin-usuarios")
        }
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line 
  }, []);

  const handleChangeEmail = (text) => {
    setState({ ...state, email: text })
  }

  const handleChangeContraseña = (text) => {
    setState({ ...state, password: text })
  }

  return (
    <div className="container">
      <div className="bg-login">
        <div className="d-flex">
          <div className="col"></div>
          <div className="col">
            <div className="text-big text-cafe">Iniciar Sesión</div>
            <div className="card-shadow p-3">
              <div className="text-gray text-bold my-3">Correo Electrónico</div>
              <div className="my-2 d-flex">
                <input
                  placeholder="Email"
                  className="input-text"
                  type="email"
                  value={state.email}
                  onChange={(evt) => {
                    handleChangeEmail(evt.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                />
                <div style={{ width: '40px', height: '30px' }}></div>
              </div>
              <div className="text-gray text-bold">Contraseña</div>
              <div className="my-2 d-flex">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input-text"
                  placeholder="Ingrese su contraseña"
                  value={state.password}
                  onChange={(evt) => {
                    handleChangeContraseña(evt.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                />
                <div className="text-cafe text-medium p-2 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => {
                  setShowPassword(!showPassword)
                }}>
                  {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </div>
              </div>
              {espera ?

                <div className="mt-5 d-flex justify-content-center">
                  <div className="loader"></div>
                </div>
                :
                <div className="d-flex flex-column justify-conten-center align-items-center">
                  <div className="text-gray mt-3 btn-rosa text-center" onClick={() => {
                    handleLogin()
                  }}>Iniciar sesión</div>
                </div>
              }

              <div className="text-danger mt-2 text-center" style={{ height: '30px' }}>{error ? error : ''}</div>
              <div className="text-cafe mt-3 text-cafe text-center">¿No tienes cuenta?
                <span className="text-rosa cursor-pointer ms-2" style={{ textDecoration: 'underline' }} onClick={() => {
                  navigate("/registro")
                }}>Registrate</span>
              </div>
              <div className="text-cafe mt-3 text-cafe text-center">¿Se te olvidó tu contraseña?
                <span className="text-rosa cursor-pointer ms-2" style={{ textDecoration: 'underline' }} onClick={() => {
                  navigate("/recuperar")
                }}>Recuperar</span>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
