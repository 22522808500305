import icono1 from '../../../assets/images/icono1.png'
import icono2 from '../../../assets/images/icono2.png'
import icono3 from '../../../assets/images/icono3.png'
import icono4 from '../../../assets/images/icono4.png'

function TarjetasInfo() {
    return (
        <div className="bg-gradiente-rosa">
            <div className="container">
                <div className="d-flex pt-5 pb-3">
                    <div className="card-shadow bg-white d-flex p-2 ms-3 w-50">
                        <div className='p-3'><img src={icono1} alt="icono" /></div>
                        <div>
                            <div className='text-cafe text-bold'>Centro de Excelencia</div>
                            <div className='text-rosa'>Nuestro centro dermatológico se dedica a proporcionar el mejor cuidado para tu piel, combinando tecnología avanzada y experiencia profesional.</div>
                        </div>
                    </div>
                    <div className="card-shadow bg-white d-flex p-2 ms-3 w-50">
                        <div className='p-3'><img src={icono2} alt="icono" /></div>
                        <div>
                            <div className='text-cafe text-bold'>Especialistas Certificados</div>
                            <div className='text-rosa'>Contamos con un equipo de dermatólogos altamente calificados y certificados, comprometidos con la excelencia en el tratamiento de diversas condiciones de la piel.</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex pt-3 pb-5">
                    <div className="card-shadow bg-white d-flex p-2 ms-3 w-50">
                        <div className='p-3'><img src={icono3} alt="icono" /></div>
                        <div>
                            <div className='text-cafe text-bold'>Servicios Completos</div>
                            <div className='text-rosa'>Desde consultas generales hasta tratamientos estéticos avanzados, ofrecemos una gama completa de servicios para satisfacer todas tus necesidades dermatológicas.</div>
                        </div>
                    </div>
                    <div className="card-shadow bg-white d-flex p-2 ms-3 w-50">
                        <div className='p-3'><img src={icono4} alt="icono" /></div>
                        <div>
                            <div className='text-cafe text-bold'>Atención Personalizada</div>
                            <div className='text-rosa'>Cada paciente recibe una atención personalizada, con un plan de tratamiento adaptado a sus necesidades específicas para asegurar los mejores resultados posibles.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TarjetasInfo