import OverLayIU from "../../../components/overlay_ui";
import IconUI from "../../../components/icon_ui";
import { GrClose } from "react-icons/gr";
import { urlBack } from "utils/urlEnpoint";
import { useContext, useState } from "react";
import { NotificacionContext } from "context/notificacion_context";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function MasInfoVenta({ onclose, data }) {
    const navigate = useNavigate()
    const { showNotification } = useContext(NotificacionContext)
    const [error, setError] = useState(false)
    const [showComment, setShowComment] = useState(false)
    const [productSelected, setProductSelected] = useState({})

    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [comment, setComment] = useState('');

    const [admin, setAdmin] = useState(false)

    const handleStarClick = (value) => {
        setRating(value);
    };

    const postComment = async (url, data, token) => {
        try {
            const resp = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(data),
                mode: "cors",
            });

            if (!resp.ok) {
                throw new Error(`Error HTTP! status: ${resp.status}`);
            }

            const json = await resp.json();
            return json;
        } catch (error) {
            console.error("Error al obtener los productos:", error);
            return { error: error.message };
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (rating === 0 || comment.trim() === '') {
            showNotification(
                "warning",
                "Por favor",
                "Seleccione una calificación y escriba un comentario.",
                null,
                5
            )
            return;
        }
        let data = {
            calificacion: rating,
            comentario: comment,
            producto_id: productSelected.producto_id
        }
        console.log(data)

        const token = localStorage.getItem("token")
        const comentario = await postComment(`${urlBack}comentario_producto.php`, data, token);

        if (comentario.success) {
            showNotification(
                "success",
                "Comentario Añadido",
                "La operación se realizó correctamente",
                null,
                5
            );
            // setRating(0);
            // setComment('');
            onclose(true)
        } else {
            if (comentario.error === 'Error en la respuesta del servidor: 401') {
                showNotification(
                    "warning",
                    "Se caducó la Sesión",
                    "vuelve a Ingresar de nuevo",
                    null,
                    5
                );
                navigate("/login")
            } else {
                if (comentario.error === 'Error HTTP! status: 403') {

                    setError("Ya has comentado este producto");
                } else {

                    setError(comentario.error);
                }
            }



        };
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let admin = localStorage.getItem("tipoUsuario")

            if (admin === "Admin") {
                setAdmin(true)
            } else {
                setAdmin(false)
            }
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line 
    }, []);


    return (
        <>
            <OverLayIU
                onClick={() => {
                    onclose();
                }}
            />
            <div className="wizard-max-1000">
                <div className="d-flex mb-3">
                    <div className="col ">  </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            onclose();
                        }}
                    >
                        <IconUI size={15}>
                            <GrClose></GrClose>
                        </IconUI>
                    </div>
                </div>
                {showComment ?
                    <div>
                        <div className="d-flex">
                            <div>
                                <div className="text-cafe text-bold btn-add d-flex justify-content-center align-items-center" onClick={() => { setShowComment(false) }}>{`<`}</div>
                            </div>
                            <div className="col"></div>
                        </div>
                        <div className="d-flex flex-column justify-conten-center align-items-center">
                            <div className="text-cafe text-bold">Califica este Produto</div>
                        </div>

                        <div className="my-3 text-rosa text-center text-bolder">{productSelected.producto}</div>
                        <div className="mt-3 d-flex justify-content-center">
                            <div className="">
                                <div><img style={{ width: '70px', height: '70px' }} src={`${urlBack}${productSelected.imagenes[0]}`} alt="producto" /></div>
                            </div>
                            <div>
                                <div className="">

                                    <div className="stars">
                                        {[...Array(5)].map((_, index) => {
                                            const value = index + 1;
                                            return (
                                                <span
                                                    key={value}
                                                    className={`star ${value <= (hover || rating) ? 'filled' : ''}`}
                                                    onClick={() => handleStarClick(value)}
                                                    onMouseEnter={() => setHover(value)}
                                                    onMouseLeave={() => setHover(0)}
                                                >
                                                    ★
                                                </span>
                                            );
                                        })}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center my-3" style={{ width: '70%', margin: 'auto' }}>
                            <textarea
                                className="input-area"
                                placeholder="¿Este Producto te resulto útil?"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                            <button className="btn-rosa mt-3" onClick={handleSubmit}>
                                Enviar Comentario
                            </button>
                        </div>
                        <div className="text-danger mt-2 text-center" style={{ height: '30px' }}>{error ? error : ''}</div>

                    </div>
                    :
                    <>
                        <div className="d-flex flex-column justify-conten-center align-items-center">
                            <div className="text-cafe text-bold">Venta del Cliente : {data.email_cliente}</div>

                        </div>
                        <div className="mt-3 text-rosa text-bold border-bottom">Total : {data.total}</div>

                        <div className="mt-3 text-rosa text-bold border-bottom">PRODUCTOS : </div>
                        {data.productos.map((Producto, i) => {
                            console.log(Producto)
                            return (
                                <div className="d-flex my-3 border-bottom align-items-center" key={i}>
                                    <div>
                                        <img style={{ width: '70px', height: '70px' }} src={`${urlBack}${Producto?.imagenes[0]}`} alt="producto" />
                                    </div>
                                    <div>
                                        <div className="mt-3 text-rosa text-bold ">Producto : {Producto.producto}</div>
                                        <div className="text-rosa text-bold ">Cantidad : {Producto.cantidad}</div>
                                        <div className="text-rosa text-bold ">Precio : {Producto.precio_unitario}</div>
                                    </div>
                                    {!admin ?
                                        <div>
                                            <div className="btn-rosa text-center ms-3" style={{ width: '150px' }} onClick={() => {
                                                setShowComment(true)
                                                setProductSelected(Producto)
                                            }}> Añadir Comentario</div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            )
                        })}
                    </>
                }



            </div>
        </>
    );
}
