import HeaderHome from "components/headerHome"
import MenuLateral from "components/menuLateral"
import Comentarios from "./sections/comentarios"

function AdminComentarios() {
  return (
    <div className="bg-gray">
      <div className="d-flex">
        <MenuLateral />
        <div className="mx-5 w-100">
          <HeaderHome/>
          <Comentarios/>
        </div>
      </div>
    </div>
  )
}

export default AdminComentarios