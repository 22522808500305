
function Intro() {
    return (
        <div className="container">
            <div className="bg-derma">
                <div className="d-flex justify-content-end align-items-end">
                    <div className="col"></div>
                    <div className="col">
                        <div className="text-cafe text-big text-bolder mt-5">Dermatología</div>
                        <div className="text-rosa text-medium my-3">Protege, cuida y transforma tu piel.</div>
                        <div className="text-rosa">En Peroli, cuidamos de tu piel con atención especializada. Visítanos y descubre tratamientos únicos para mejorar tu salud, realzar tu belleza y atender todas tus necesidades dermatológicas. ¡Te esperamos!</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro