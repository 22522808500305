import vector from '../../assets/images/Vector.png'
import Cirugia from './sections/cirugia';
import Consulta from './sections/consulta';
import Intro from './sections/intro';
import Medicina from './sections/medicina';
import MedicinaCancer from './sections/medicinaCancer';
import MedicinaClinica from './sections/medicinaClinica';

function Dermatologia() {
  return (
    <>
      <Intro/>
      <div className="d-flex justify-content-end" style={{ marginTop: '-350px', zIndex: '100' }}>
        <img src={vector} alt="vector" />
      </div>
      <Medicina/>
      <MedicinaClinica/>
      <MedicinaCancer/>
      <Consulta/>
      <Cirugia/>
    </>

  );
}

export default Dermatologia;
