
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { urlBack } from "utils/urlEnpoint";
import { NotificacionContext } from "context/notificacion_context";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";


const enviarData = async (url, data) => {
    try {
        const resp = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        });

        if (!resp.ok) {
            throw new Error(`Error en la respuesta del servidor: ${resp.status}`);
        }

        return await resp.json();
    } catch (error) {
        console.error("Error al enviar los datos:", error);
        return { error: error.message };
    }
};

export default function RestablecerContraseña() {
    const { showNotification } = useContext(NotificacionContext)
    let navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [token, setToken] = useState('')
    const [error, setError] = useState(null);
    const [espera, setEspera] = useState(false);
    const [usuario, setUsuario] = useState({
        clave: "",
        clave1: "",
    });

    const handleRestablecerContrasena = async () => {
        setEspera(true);

        const data = {
            token: token,
            password: usuario.clave,
        };
        if (usuario.clave && usuario.clave1) {
            if (usuario.clave.length > 5 && usuario.clave1 > 5){
                if (usuario.clave === usuario.clave1) {
                    if (data.token) {
                        const respJson = await enviarData(`${urlBack}restablecerContrasena.php`, data);
    
                        if (respJson.message === "Contraseña actualizada con éxito.") {
                            showNotification(
                                "success",
                                "Cambio de Contraseña",
                                "La operación se realizó correctamente",
                                null,
                                5
                            );
                            navigate("/login")
                        } else {
                            setError(respJson.error);
                            setEspera(false);
                        }
                    }
                } else {
                    setError('Las contraseñas no coinciden.')
                }
            }else{
                setError('Favor de ingresar más de 5 caracteres')
            }
            
        } else {
            setError('Favor de ingresar los datos.')
        }

        setEspera(false);
    };


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        if (token) {
            setToken(token)
        } else {
            navigate("/login")
        }
        // eslint-disable-next-line
    }, []);

    // Limpia el mensaje de error después de 5 segundos o si el usuario empieza a escribir
    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => setError(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    const handleChange = (field, value) => {
        setUsuario({ ...usuario, [field]: value });
    };

    return (
        <div className="container">
            <div className="bg-login">
                <div className="d-flex">
                    <div className="col"></div>
                    <div className="col">
                        <div className="text-big text-cafe">Restablecer contraseña</div>
                        <div className="card-shadow p-3">
                            <div className="text-rosa mt-2">Recomendación 6 caracteres</div>
                            <div className="my-3 text-gray text-bold">Nueva Contraseña:</div>
                            <div className="d-flex">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="input-text"
                                    placeholder="Nueva Contraseña"
                                    value={usuario.clave}
                                    onChange={(e) => handleChange("clave", e.target.value)}
                                />
                                <div
                                    className="text-cafe cursor-pointer"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                </div>
                            </div>
                            <div className="my-3 text-gray text-bold">Repetir Contraseña:</div>
                            <div className="d-flex">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="input-text"
                                    placeholder="Repetir Contraseña"
                                    value={usuario.clave1}
                                    onChange={(e) => handleChange("clave1", e.target.value)}
                                />
                                <div
                                    className="text-cafe cursor-pointer"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                </div>
                            </div>

                            {espera ?

                                <div className="mt-5 d-flex justify-content-center">
                                    <div className="loader"></div>
                                </div>
                                :
                                <div className="d-flex flex-column justify-conten-center align-items-center">
                                    <div className="text-gray mt-3 btn-rosa text-center" onClick={() => {
                                        handleRestablecerContrasena()
                                    }}>Cambiar contraseña</div>
                                </div>
                            }
                            <div className="text-danger mt-2 text-center" style={{ height: '30px' }}>{error ? error : ''}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
