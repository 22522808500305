import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { urlBack } from "utils/urlEnpoint";
import { NotificacionContext } from "context/notificacion_context";

const enviarData = async (url, data) => {
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    });

    if (!resp.ok) {
      throw new Error(`Error en la respuesta del servidor: ${resp.status}`);
    }

    return await resp.json();
  } catch (error) {
    console.error("Error al enviar los datos:", error);
    return { error: error.message };
  }
};

export default function Registro() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { showNotification } = useContext(NotificacionContext)
  const [espera, setEspera] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Controla el paso actual

  const [usuario, setUsuario] = useState({
    idTipoUsuario: "2",
    correo: "",
    nombre: "",
    apellidos: "",
    clave: "",
    celular: "",
    calle: "",
    colonia: "",
    numero: "",
    numero_interior: "",
    codigo_postal: "",
    estado: "",
    ciudad: "",
    referencias: "",
  });

  const handleChange = (field, value) => {
    setUsuario({ ...usuario, [field]: value });
  };

  const handleNextStep = () => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async () => {
    setEspera(true);
    const respJson = await enviarData(`${urlBack}registrar.php`, usuario);
    if (respJson.registrado) {
      showNotification(
        "success",
        "Usuario Añadido",
        "La operación se realizó correctamente",
        null,
        5
      );
      setUsuario({
        correo: "",
        nombre: "",
        apellidos: "",
        clave: "",
        idTipoUsuario: "2",
        celular: "",
        calle: "",
        colonia: "",
        numero: "",
        numero_interior: "",
        codigo_postal: "",
        estado: "",
        ciudad: "",
        referencias: ""
      })
      setError("");
      navigate("/login")
    } else {
      setError(respJson.error);
    }
    setEspera(false);
  };

  return (
    <div className="container">
      <div className="bg-login" style={{ padding: '20px 0', height: 'fit-content' }}>

        <div className="d-flex justify-content-end align-items-center">

          <div className="card-shadow p-3" style={{ width: "100%", maxWidth: 500 }}>
            <div className="text-big text-cafe" style={{ textAlign: 'center' }}>Registro</div>
            <div className="steps-container">
              <div className="step">
                <div className={`circle ${currentStep === 1 ? "active" : ""}`}>1</div>
                <span className="label">Datos personales</span>
              </div>
              <div className="divider" />
              <div className="step">
                <div className={`circle ${currentStep === 2 ? "active" : ""}`}>2</div>
                <span className="label">Dirección</span>
              </div>
            </div>
            {currentStep === 1 && (
              <>
                <div className="d-flex">
                  <div className="col me-3">
                    <div className="my-3 text-gray text-bold">Nombre:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Nombre"
                      value={usuario.nombre}
                      onChange={(e) => handleChange("nombre", e.target.value)}
                    />
                    <div className="my-3 text-gray text-bold">Apellidos:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Apellidos"
                      value={usuario.apellidos}
                      onChange={(e) => handleChange("apellidos", e.target.value)}
                    />

                  </div>
                  <div className="col">
                    <div className="my-3 text-gray text-bold">Celular:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Celular"
                      value={usuario.celular}
                      onChange={(e) => handleChange("celular", e.target.value)}
                    />
                    <div className="my-3 text-gray text-bold">Contraseña:</div>
                    <div className="d-flex">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="input-text"
                        placeholder="Contraseña"
                        value={usuario.clave}
                        onChange={(e) => handleChange("clave", e.target.value)}
                      />
                      <div
                        className="text-cafe cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-3 text-gray text-bold">Correo Electrónico:</div>
                <input
                  type="email"
                  className="input-text"
                  placeholder="Correo"
                  value={usuario.correo}
                  onChange={(e) => handleChange("correo", e.target.value)}
                />


                <div className="mt-4 text-center">
                  <button className="btn-rosa" onClick={handleNextStep}>
                    Siguiente
                  </button>
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <div className="d-flex">
                  <div className="col me-3">
                    <div className="my-3 text-gray text-bold">Calle:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Calle"
                      value={usuario.calle}
                      onChange={(e) => handleChange("calle", e.target.value)}
                    />
                    <div className="my-3 text-gray text-bold">Colonia:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Colonia"
                      value={usuario.colonia}
                      onChange={(e) => handleChange("colonia", e.target.value)}
                    />
                    <div className="my-3 text-gray text-bold">Número Exterior:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Número Exterior"
                      value={usuario.numero}
                      onChange={(e) => handleChange("numero", e.target.value)}
                    />
                    <div className="my-3 text-gray text-bold">Número Interior:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Número Interior"
                      value={usuario.numero_interior}
                      onChange={(e) => handleChange("numero_interior", e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <div className="my-3 text-gray text-bold">Codigo Postal:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Codigo Postal"
                      value={usuario.codigo_postal}
                      onChange={(e) => handleChange("codigo_postal", e.target.value)}
                    />
                    <div className="my-3 text-gray text-bold">Estado:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Estado"
                      value={usuario.estado}
                      onChange={(e) => handleChange("estado", e.target.value)}
                    />
                    <div className="my-3 text-gray text-bold">Ciudad:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Ciudad"
                      value={usuario.ciudad}
                      onChange={(e) => handleChange("ciudad", e.target.value)}
                    />
                    <div className="my-3 text-gray text-bold">Referencias:</div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Referencias"
                      value={usuario.referencias}
                      onChange={(e) => handleChange("referencias", e.target.value)}
                    />
                  </div>
                </div>



                <div className="mt-4 d-flex justify-content-center align-items-center">
                  <button className="btn-rosa me-3 px-2" style={{ width: 'fit-content' }} onClick={handlePrevStep}>
                    Atrás
                  </button>
                  {espera ?

                    <div className="d-flex justify-content-center">
                      <div className="loader"></div>
                    </div>
                    :
                    <div className="d-flex flex-column justify-conten-center align-items-center">
                      <div className="text-gray btn-rosa text-center px-4" style={{ width: 'fit-content' }} onClick={() => {
                        handleSubmit()
                      }}>Crear Usuario</div>
                    </div>
                  }
                </div>
                <div className="text-danger mt-2 text-center" style={{ height: '30px' }}>{error ? error : ''}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
