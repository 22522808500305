import OverLayIU from "../../../components/overlay_ui";
import IconUI from "../../../components/icon_ui";
import { GrClose } from "react-icons/gr";
import { urlBack } from "utils/urlEnpoint";

export default function MasInfoProducto({ onclose, data }) {

    return (
        <>
            <OverLayIU
                onClick={() => {
                    onclose();
                }}
            />
            <div className="wizard-max-1000">
                <div className="d-flex mb-3">
                    <div className="col ">  </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            onclose();
                        }}
                    >
                        <IconUI size={15}>
                            <GrClose></GrClose>
                        </IconUI>
                    </div>
                </div>
                <div className="d-flex flex-column justify-conten-center align-items-center">
                    <div className="text-cafe text-bold">Producto : {data.nombre}</div>

                </div>
                <div className="mt-3 text-rosa text-bold border-bottom">Descripción : {data.descripcion}</div>
                <div className="mt-3 text-rosa text-bold border-bottom">Precio : {data.precio}</div>
                <div className="mt-3 text-rosa text-bold border-bottom">Stock : {data.stock}</div>
                <div className="mt-3 text-rosa text-bold border-bottom">Tipo de descuento: {data.tipo_descuento}</div>
                <div className="mt-3 text-rosa text-bold border-bottom">Precio total : {data.monto_descuento}</div>
                <div className="row">
                    {data.imagenes.map((img, i) => {
                 
                        return (
                            <div className="col my-3" key={i}>
                                <img style={{ width: '100px' }} src={`${urlBack}${img}`} alt="Producto"/>
                            </div>
                        )
                    })}
                </div>

            </div>
        </>
    );
}
