import { MdPerson } from "react-icons/md";
import IconUI from "./icon_ui";
import { useState, useEffect } from "react";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function HeaderHome() {
    const [name, setName] = useState('')
    const [usertype, setUsertype] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            let FullName = localStorage.getItem("nombre")
            let UserType = localStorage.getItem("tipoUsuario")
            setName(FullName)
            setUsertype(UserType)

        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line 
    }, [window.location.pathname])

    const onLogout = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("nombre")
        localStorage.removeItem("tipoUsuario")
        navigate("/login")
    }
    return (
        <div className="headerHome">
            <div className="d-flex">
                <div className="col"></div>
                <div className="d-flex position-relative" style={{ cursor: "pointer" }} onClick={toggleDropdown}>
                    <div className="miniatura">
                        <IconUI>
                            <MdPerson />
                        </IconUI>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center text-rosa ms-3">
                        <div>{name}</div>
                        <div className="text-small">{usertype}</div>
                    </div>
                    <div
                        className="d-flex justify-content-center align-items-center ms-3 text-rosa"

                    >
                        <IconUI>
                            {isOpen ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                        </IconUI>
                    </div>

                    {isOpen && (
                        <div
                            className="position-absolute bg-white border rounded shadow p-2 mt-5"
                            style={{ right: 0 }}
                        >
                            <button
                                className="btn-rosa-100"
                                onClick={() => {
                                    onLogout()
                                }}
                            >
                                Cerrar sesión
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HeaderHome