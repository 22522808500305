import Nosotros from "./pages/Nosotros/Nosotros";
import Contactanos from "./pages/Contactanos/Contactanos";
import Inicio from "./pages/Inicio/Inicio";
import Dermatologia from "./pages/Dermatologia/Dermatologia"; // Importa el nuevo componente
import Checkout from "pages/checkout";
import Login from "pages/Login/Login";
import Favoritos from "pages/favoritos/favoritos";
import Farmacia from "pages/farmacia/farmacia";
import DetalleProducto from "pages/farmacia/sections/detalleProducto";
import AdminUsuarios from "pages/Admin/AdminUsuarios";
import PrivateRoute from "auth/privateRoute";
import AdminProductos from "pages/Admin/AdminProductos";
import AdminVentas from "pages/Admin/AdminVentas";
import UsuarioCompras from "pages/Usuario/UsuarioCompras";
import Registro from "pages/Login/Registro";
import RecuperarContraseña from "pages/Login/recuperarContraseña";
import RestablecerContraseña from "pages/Login/restablecerContraseña";
import AdminComentarios from "pages/Admin/AdminComentarios";

const routes = [
  {
    key: "inicio", // Clave única
    name: "INICIO",
    route: "/",
    component: <Inicio />,
  },
  {
    key: "dermatologia", // Clave única
    name: "DERMATOLOGIA",
    route: "/dermatologia",
    component: <Dermatologia />,
  },
  {
    key: "nosotros", // Clave única
    name: "NOSOTROS",
    route: "/nosotros",
    component: <Nosotros />,
  },
  {
    key: "favoritos", // Clave única
    name: "FAVORITOS",
    route: "/favoritos",
    component: <Favoritos/>,
  },
  {
    key: "contactanos", // Clave única
    name: "CONTACTANOS",
    route: "/contactanos",
    component: <Contactanos />,
  },
  {
    key: "farmacia", // Clave única
    name: "FARMACIA",
    route: "/Farmacia",
    component: <Farmacia/>,
  },
  {
    key: "detalleProducto", // Clave única
    name: "DETALLEPRODUCTO",
    route: "/detalleProducto/:id",
    component: <DetalleProducto/>,
  },
  {
    key: "login", // Clave única
    name: "LOGIN",
    route: "/login",
    component: <Login />,
  },
  {
    key: "registro", // Clave única
    name: "REGISTRO",
    route: "/registro",
    component: <Registro />,
  },
  {
    key: "Recuperar", // Clave única
    name: "RECUPERAR",
    route: "/recuperar",
    component: <RecuperarContraseña />,
  },
  {
    key: "restablecerContrasena", // Clave única
    name: "RESTABLECER",
    route: "/restablecer-contrasena",
    component: <RestablecerContraseña />,
  },
  {
    key: "AdministradorUsuarios", // Clave única
    name: "Usuarios",
    route: "/admin-usuarios",
    component: <PrivateRoute><AdminUsuarios/></PrivateRoute>,
  },
  {
    key: "AdministradorProductos", // Clave única
    name: "Productos",
    route: "/admin-productos",
    component: <PrivateRoute><AdminProductos/></PrivateRoute>,
  },
  {
    key: "AdministradorVentas", // Clave única
    name: "Ventas",
    route: "/admin-ventas",
    component: <PrivateRoute><AdminVentas/></PrivateRoute>,
  },
  {
    key: "UsuarioCompras", // Clave única
    name: "Compras",
    route: "/mis-compras",
    component: <PrivateRoute><UsuarioCompras/></PrivateRoute>,
  },
  {
    key: "comentarios", // Clave única
    name: "Comentarios",
    route: "/admin-comentarios",
    component: <PrivateRoute><AdminComentarios/></PrivateRoute>,
  },
  {
    key: "carrito", // Clave única
    name: "carrito",
    route: "/carrito",
    component: <Checkout/>,
  },
];

export default routes;
