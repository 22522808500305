import facebook from '../../../assets/images/Facebook.png'
import instagram from '../../../assets/images/Instagram.png'
import groupone from '../../../assets/images/group1.png'
import grouptwo from '../../../assets/images/group2.png'
import groupthree from '../../../assets/images/group3.png'

function Centroderma() {
    return (
        <div className="bg-section-one">
            <div className="container" data-aos="fade-up">
                <div className="text-big text-cafe text-bolder pt-5" style={{
                    lineHeight: '1'
                }}>Centro <br />Dermatológico</div>
                <div className="text-rosa text-medium mt-3">Cuidado experto para una <br /> piel saludable y radiante.</div>
                <div className="d-flex mt-3">
                    <a href="https://www.facebook.com/CentroDermaPeroli/" target='_blank' rel="noreferrer" >
                        <img src={facebook} className='me-4 cursor-pointer' alt="facebook" />
                    </a>
                    <a href="https://www.instagram.com/CentroDermaPeroli/" target='_blank' rel="noreferrer" >
                        <img src={instagram} className='cursor-pointer' alt="instagram" />
                    </a>
                </div>
                <div className='d-flex padding-section1 width-section1'>
                    <div className='text-white'>
                        <div><img src={groupone} alt="pacientes"/></div>
                        {/* <div className='text-medium text-bolder'>1,000+</div> */}
                        <div className='text-bolder'>Pacientes Satisfechos</div>
                        <div className='text'>Desde consultas rutinarias hasta tratamientos avanzados, confiamos en nuestra experiencia.</div>
                    </div>
                    <div className='text-white mx-2'>
                        <div><img src={grouptwo} alt="tratamientos"/></div>
                        {/* <div className='text-medium text-bolder'>50+</div> */}
                        <div className='text-bolder'>Tratamientos Ofrecidos</div>
                        <div className='text'>Desde cuidado de la piel hasta soluciones especializadas, cubrimos todas tus necesidades dermatológicas.</div>
                    </div>
                    <div className='text-white'>
                        <div><img src={groupthree} alt="experiencia"/></div>
                        {/* <div  className='text-medium text-bolder'>10</div> */}
                        <div className='text-bolder'>Experiencia</div>
                        <div className='text'>Más de una década brindando atención experta y personalizada para una piel y cabello mas saludables.</div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Centroderma