import IconUI from "components/icon_ui";
import { useState } from "react";
import { useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import { urlBack } from "utils/urlEnpoint";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { NotificacionContext } from "context/notificacion_context";
import { useContext } from "react";
import CrearProducto from "./crearProducto";
import MasInfoProducto from "./masInfoProducto";
import EditarProducto from "./editarProducto";
import EliminarProducto from "./eliminarProducto";

function UsuariosProductos() {
    const { showNotification } = useContext(NotificacionContext)
    const [productos, setProductos] = useState([])
    const [popup, setPopup] = useState(false)
    const [popupMoreInfo, setPopupMoreInfo] = useState(false)
    const [popupEditProducto, setPopupEditProducto] = useState(false)
    const [popupDeleteProducto, setPopupDeleteProducto] = useState(false)
    const [productoSeleccionado, setProductoSeleccionado] = useState({})
    const navigate = useNavigate()


    const getProductos= async (url, token) => {
        try {
            const resp = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                mode: "cors",
                "Authorization": `Bearer ${token}`
            });

            if (!resp.ok) {
                throw new Error(`Error HTTP! status: ${resp.status}`);
            }

            const json = await resp.json();
            return json;
        } catch (error) {
            console.error("Error al obtener los productos:", error);
            return { error: error.message };
        }
    }


    const fetchProductos = async () => {
        const token = localStorage.getItem("token")
        const productos = await getProductos(`${urlBack}productoss.php`, token);
      
        if (productos) {
            if (productos.error === 'Error HTTP! status: 401') {
                localStorage.removeItem("token")
                localStorage.removeItem("nombre")
                localStorage.removeItem("tipoUsuario")
                showNotification(
                    "warning",
                    "Se caducó la Sesión",
                    "vuelve a Ingresar de nuevo",
                    null,
                    5
                );
                navigate("/login")
            } else {
                setProductos(productos.data)
            }
        }
    };

    const deleteProducto = async (url, token) => {
        try {
            const resp = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                mode: "cors",
                "Authorization": `Bearer ${token}`
            });

            if (!resp.ok) {
                throw new Error(`Error HTTP! status: ${resp.status}`);
            }

            const json = await resp.json();
            return json;
        } catch (error) {
            console.error("Error al obtener los productos:", error);
            return { error: error.message };
        }
    }

    const handleDeleteProducto = async (id) => {
        const token = localStorage.getItem("token")
        const users = await deleteProducto(`${urlBack}productoss.php?id=${id}`, token);
        if (users) {
            if (users.error === 'Error HTTP! status: 401') {
                localStorage.removeItem("token")
                localStorage.removeItem("nombre")
                localStorage.removeItem("tipoUsuario")
                showNotification(
                    "warning",
                    "Se caducó la Sesión",
                    "vuelve a Ingresar de nuevo",
                    null,
                    5
                );
                navigate("/login")
            } else {
                
                fetchProductos()
            }
        }
    }


    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            fetchProductos();
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line 
    }, []);
    const columns = [
        {
            name: "imagenes",
            label: "Imagen",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = productos[dataIndex]?.imagenes;
                    return (
                        <div >
                            <img style={{width:'50px', height:'50px'}} src={`${urlBack}${imagen[0]}`} alt="Producto" />
                        </div>
                    );
                },
            },
        },
        {
            name: "nombre",
            label: "Nombre",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombre = productos[dataIndex]?.nombre;
                    return (
                        <div >
                            {nombre}
                        </div>
                    );
                },
            },
        },
        {
            name: "descripcion",
            label: "Descripción",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let descripcion = productos[dataIndex]?.descripcion;
                    return (
                        <div>
                            {descripcion}
                        </div>
                    );
                },
            },
        },
        {
            name: "precio",
            label: "Precio",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let precio = productos[dataIndex]?.precio;
                    return (
                        <div>
                            {precio}
                        </div>
                    );
                },
            },
        },
        {
            name: "stock",
            label: "Stock",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let stock = productos[dataIndex]?.stock;
                    return (
                        <div>
                            {stock}
                        </div>
                    );
                },
            },
        },
        {
            name: "tipo_descuento",
            label: "Tipo Descuento",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let tipo_descuento = productos[dataIndex]?.tipo_descuento;
                    return (
                        <div>
                            {tipo_descuento}
                        </div>
                    );
                },
            },
        },
        {
            name: "monto_descuento",
            label: "Precio total",
            align: "center",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let monto_descuento = productos[dataIndex]?.monto_descuento;
                    return (
                        <div>
                            {monto_descuento}
                        </div>
                    );
                },
            },
        },
        {
            name: "acciones",
            label: "Acciones",

            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => {
                    return (
                        <>
                            <div className="acciones">
                                <div className="btn-acciones-out" onClick={() => {
                                    setPopupMoreInfo(true)
                                    setProductoSeleccionado(productos[dataIndex])
                                }}>
                                    <IconUI size={'15px'}>
                                        <IoEyeOutline />
                                    </IconUI>
                                </div>
                                <div className="btn-acciones" onClick={() => {
                                    setPopupEditProducto(true)
                                    setProductoSeleccionado(productos[dataIndex])
                                }}>
                                    <IconUI size={'15px'}>
                                        <FaRegEdit />
                                    </IconUI>
                                </div>
                                <div className="btn-acciones-out" onClick={() => {
                                    setPopupDeleteProducto(true)
                                    setProductoSeleccionado(productos[dataIndex])
                                }}>
                                    <IconUI size={'15px'}>
                                        <MdDeleteOutline />
                                    </IconUI>
                                </div>
                            </div>
                        </>
                    );
                },
            },
        },
    ];
    const options = {
        selectableRows: "none",
        tableBodyHeight: "auto",
        textLabels: {
            body: {
                // noMatch: isLoading ? <Loader /> : "Lo sentimos, no hay coincidencias en la busqueda",
                noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todo",
                title: "Filtros",
                reset: "Restaurar",
            },
            viewColumns: {
                title: "Ver columnas",
                titleAria: "Mostrar/Ocultar columnas en la tabla",
            },
            selectedRows: {
                text: "columna(s) selecciona(as)",
                delete: "Eliminar",
                deleteAria: "Eliminar filas seleccionadas",
            },
        },
        responsive: "standard",
        download: false,
        print: false,
        filter: false,
        viewColumns: false,

        // downloadOptions: {

        //     filename: 'Bitacora.csv',
        //     filterOptions: {
        //         useDisplayedColumnsOnly: false,
        //         useDisplayedRowsOnly: false,
        //     }
        // },
    };
    return (
        <>
            <div className='mt-3 bg-home'>
                <div className='text-cafe text-medium'>Productos</div>
                <div className='btn-rosa-100 d-flex mt-3 align-items-center' style={{ width: '200px' }} onClick={() => {
                    setPopup(true)
                }}>
                    <div>
                        <IconUI>
                            <FiPlus />
                        </IconUI>
                    </div>
                    <div className="ms-3" >Nuevo Producto</div>
                </div>
                <div className="mt-4">
                    {productos.length > 0 ?
                        <MUIDataTable
                            style={{ Overflow: "hidden" }}
                            title={"Productos"}
                            data={productos}
                            columns={columns}
                            options={options}
                        // onDownload={false}
                        />
                        :
                        null
                    }

                </div>
            </div>
            {popup ?
                <CrearProducto onclose={(bandera) => {
                    setPopup(false)

                    if (bandera) {
                        fetchProductos()
                    }
                }} />
                :
                null
            }
            {popupMoreInfo ?
                <MasInfoProducto data={productoSeleccionado} onclose={() => {
                    setPopupMoreInfo(false)
                }} />
                :
                null
            }
            {popupEditProducto ?
                <EditarProducto data={productoSeleccionado} onclose={(bandera) => {
                    setPopupEditProducto(false)
                    if (bandera) {
                        fetchProductos()
                    }
                }} />
                :
                null
            }
            {popupDeleteProducto ?
                <EliminarProducto data={productoSeleccionado} onclose={(bandera) => {
                    setPopupDeleteProducto(false)
                    if (bandera) {
                        handleDeleteProducto(productoSeleccionado.id)
                    }
                }} />
                :
                null
            }
        </>
    )
}

export default UsuariosProductos