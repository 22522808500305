
function MedicinaCancer() {
    return (
        <div className="container">
            <div className="" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-end">
                    <div className="col">
                        <div className="text-cafe text-big text-bolder" style={{ lineHeight: '1' }}>Cáncer de Piel</div>
                        <div className="text-rosa text-medium my-3">Detección oportuna y tratamiento de cáncer de piel. La detección temprana es escencial para la prevención ó propagación del mismo.</div>

                        <div className="text-rosa text-between">Ejemplos de Tratamientos:</div>
                        <div className="card-shadow text-rosa p-3 mt-2"><span className="text-bolder">Dermatoscopía: </span>consiste en examinar la piel con un dermatoscopio, un instrumento que combina luz polarizada o no polarizada con lentes de aumento, lo que permite observar estructuras y patrones que no son visibles a simple vista.</div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Cirugía: </span>Extracción de tumores y márgenes de piel.</div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Criocirugía: </span>consiste en aplicar nitrógeno líquido (o en algunos casos argón) sobre las lesiones para congelarlas y destruirlas.</div>
                    </div>
                    <div className="col bg-cancer ms-5"></div>
                </div>
            </div>
        </div>
    )
}

export default MedicinaCancer