
function Cirugia() {
    return (
        <div className="container">
            <div className="" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-end">
                    <div className="col">
                        <div className="text-cafe text-big text-bolder" style={{ lineHeight: '1' }}>Cirugía Dermatológica</div>
                        <div className="text-rosa text-medium my-3">Realizamos procedimento Quirurgicos como el retiro de lunares, verrugas, lipomas, entre otros. La cirugía dermatológica se lleva a cabo en un entorno seguro y controlado, con el objetivo de obtener resultados óptimos.</div>

                        <div className="text-rosa text-between">Ejemplos de Tratamientos:</div>
                        <div className="card-shadow text-rosa p-3 mt-2"><span className="text-bolder">Excisiones Quirúrgicas: </span>Extracción de lunares y quistes.</div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Curetaje: </span>Eliminación de lesiones precoces de la piel. </div>
                        <div className="card-shadow text-rosa p-3 mt-3"><span className="text-bolder">Reconstrucción: </span>Técnicas para restaurar la apariencia de la piel.</div>
                    </div>
                    <div className="col bg-cirugia ms-4"></div>

                </div>
            </div>
        </div>
    )
}

export default Cirugia