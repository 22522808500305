import { useEffect, useState, useContext } from 'react';
import logoPeroli from '../assets/images/peroli_logo.png'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { useNavigate } from 'react-router-dom';
import { ProductosContext } from 'context/productos_context';

function Header() {
  const [activePath, setactivePath] = useState({
    path: ""
  })
  const { productosState } = useContext(ProductosContext)
  const [carrito, setCarrito] = useState([])
  const [activo, setActivo] = useState(false)

  const [precioProductos, setPrecioProductos] = useState(0)
  const [cantidadProductos, setCantidadProductos] = useState(0)
  let navigate = useNavigate()

  useEffect(() => {

    const carritoLocalStorage = localStorage.getItem("carrito");

    if (carritoLocalStorage) {

      const carritoParseado = JSON.parse(carritoLocalStorage);
      setCarrito(carritoParseado);

      let precioTotalProducto = 0;
      let cantidadTotalProducto = 0;
      carritoParseado.forEach((producto) => {
        precioTotalProducto = precioTotalProducto + (parseInt(producto.monto_descuento) * (producto.cantidad?producto.cantidad:1 ))
        cantidadTotalProducto = cantidadTotalProducto + (producto.cantidad?producto.cantidad:1)
      });
      setCantidadProductos(cantidadTotalProducto)
      setPrecioProductos(precioTotalProducto);
    }
  }, [productosState]);

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      let path = window.location.pathname
      setactivePath({ ...activePath, path })
      const token = localStorage.getItem("token");
      if (token) {
        setActivo(true)
      }else{
        setActivo(false)
      }

    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line 
  }, [window.location.pathname])


  return (
    <>
      {activo ?
        null
        :
        <div className="container">
          <div className="d-flex">
            <div className='mb-2 mt-4'><img src={logoPeroli} alt="logoPeroli" /></div>
            <div className="col"></div>
            <div className='d-flex justify-content-center align-items-center text-rosa' >
              <div className={activePath.path === "/" ? 'menu-link menu-link-active' : 'menu-link'} onClick={() => {
                navigate("/");
              }}>Inicio</div>
              <div className={activePath.path === "/dermatologia" ? 'menu-link menu-link-active mx-4' : 'menu-link mx-4'} onClick={() => {
                navigate("/dermatologia");
              }}>Dermatología</div>
              <div className={activePath.path === "/nostros" ? 'menu-link menu-link-active' : 'menu-link'} onClick={() => {
                navigate("/nosotros");
              }}>Nosotros</div>
              <div className={activePath.path === "/favoritos" ? 'menu-link menu-link-active mx-4' : 'menu-link mx-4'} onClick={() => {
                navigate("/favoritos");
              }}>Favoritos</div>
              <div className={activePath.path === "/contactanos" ? 'menu-link menu-link-active' : 'menu-link'} onClick={() => {
                navigate("/contactanos");
              }}>Contáctanos</div>
              <div className={activePath.path === "/farmacia" ? 'menu-link menu-link-active mx-4' : 'menu-link mx-4'} onClick={() => {
                navigate("/farmacia");
              }}>Farmacia</div>
              <div className={activePath.path === "/login" ? 'menu-link-login menu-link-login-active' : 'menu-link-login'} onClick={() => {
                navigate("/login");
              }}>Login</div>
              <div className={activePath.path === "/carrito" ? 'menu-link menu-link-active mx-4' : 'menu-link mx-4'} onClick={() => {
                navigate("/carrito");
              }}><LocalGroceryStoreIcon />$ {precioProductos} {`(${cantidadProductos})`}</div>
            </div>
            <div style={{display:'none'}}>{carrito.length}</div>
          </div>
        </div>
      }
    </>

  )
}

export default Header