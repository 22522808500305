import HeaderHome from "components/headerHome"
import MenuLateral from "components/menuLateral"
import Ventas from "./sections/ventas"



function AdminVentas() {

    return (
        <div className="bg-gray">
            <div className="d-flex">
                <MenuLateral />
                <div className="mx-5 w-100">
                    <HeaderHome />
                    <Ventas/>
                </div>
            </div>
        </div>
    )
}

export default AdminVentas