import logoPeroli from '../assets/images/peroli_logo.png'
import { LiaShoppingBagSolid } from "react-icons/lia";
import { MdKeyboardArrowRight } from "react-icons/md";
import IconUI from './icon_ui';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function MenuLateralUsuario() {
    const navigate = useNavigate()
    const [path, setpath] = useState('')
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
          let path = window.location.pathname
          setpath(path)
    
        }
    
        return () => {
          isMounted = false
        }
        // eslint-disable-next-line 
      }, [window.location.pathname])
    return (
        <>

            <div className='Menu_lateral'>
                <div className='text-center'>
                    <img src={logoPeroli} alt="Logo Peroli" />
                </div>
                <div className='mt-4'>
                    <div className={path === "/mis-compras"? 'p-3 my-2 d-flex opciones-menu-active':'p-3 my-2 d-flex opciones-menu'} onClick={()=>{
                        navigate("/mis-compras")
                    }}>
                        <div className='col'>
                            <div className='d-flex align-items-center'>
                                <div className='me-3'>
                                    <IconUI size={'30px'}>
                                        <LiaShoppingBagSolid />
                                    </IconUI>
                                </div>
                                <div>
                                    Mis compras
                                </div>
                            </div>
                        </div>
                        <div>
                            <IconUI size={'30px'}>
                                <MdKeyboardArrowRight />
                            </IconUI>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default MenuLateralUsuario