
import group4 from '../../../assets/images/group4.png'
import logoPeroli from '../../../assets/images/peroli_logo.png'
import group5 from '../../../assets/images/group5.png'
import group8 from '../../../assets/images/group8.png'
import group7 from '../../../assets/images/group7.png'
import group6 from '../../../assets/images/group6.png'
import carlos from '../../../assets/images/Carlos.png'
import Ana from '../../../assets/images/Ana.png'
import maria from '../../../assets/images/Maria.png'
import estrellas from '../../../assets/images/estrellas.png'

export default function Testimonios() {
  return (
    <div className="bg-purple pt-5">
      <div className="container ">
        <div className="d-flex mt-4">
          <div className="group4">
            <img className="w-100" src={group4} alt="skin" />
          </div>
          <div >
            <div ><img className="logoperoli-dos" src={logoPeroli} alt="peroli" /></div>
            <div className="pildora">
              <div className="text-cafe">Descubre cómo podemos ayudarte a lucir y sentirte mejor con la atención profesional que mereces.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5" >
        <div className="d-flex mt-5">
          <div className="padding-group5">
            <div className="text-rosa">Reseñas de nuestros pacientes</div>
            <div className="text-cafe text-between text-bolder my-5" style={{ lineHeight: '1' }}>Confiado por miles de personas</div>
            <div className="text-rosa"> Nuestros pacientes nos recomiendan por la calidad de nuestros tratamientos y la atención
              personalizada.</div>
          </div>
          <div className="">
            <img className="w-100" src={group5} alt="equipo" />
          </div>
        </div>
      </div>
      <div className="border bg-gradiente-rosa pb-5 ">
        <div className="container">
          <div className="d-flex">
            <div className="card p-3" style={{ marginTop: '-150px' }}>
              <div className="d-flex justify-content-end" style={{ marginTop: '-40px' }}><img src={group8} alt="persona" /></div>
              <div className="d-flex">
                <div><img src={carlos} alt="Carlos"  /></div>
                <div className="ms-3">
                  <div>Carlos Pérez</div>
                  <div><img src={estrellas} alt="Estrellas" /></div>
                </div>
              </div>
              <div>"El personal es muy profesional y me sentí muy bien atendido durante todo el tratamiento."</div>
            </div>
            <div className="card p-3 mx-5" style={{ marginTop: '-150px' }}>
              <div className="d-flex justify-content-end" style={{ marginTop: '-40px' }}><img src={group6} alt="persona" /></div>
              <div className="d-flex">
                <div><img src={Ana} alt="Carlos"/></div>
                <div className="ms-3">
                  <div>Ana Martínez</div>
                  <div><img src={estrellas} alt="Estrellas"  /></div>
                </div>
              </div>
              <div>"Definitivamente volveré. Los tratamientos son efectivos y el lugar es muy cómodo."</div>
            </div>
            <div className="card p-3" style={{ marginTop: '-150px' }}>
              <div className="d-flex justify-content-end" style={{ marginTop: '-40px' }}><img src={group7} alt="persona" /></div>
              <div className="d-flex">
                <div><img src={maria} alt="Carlos"  /></div>
                <div className="ms-3">
                  <div>María López</div>
                  <div><img src={estrellas} alt="Estrellas"  /></div>
                </div>
              </div>
              <div>"Excelente servicio, los resultados fueron mejores de lo que esperaba. ¡Recomendado!"</div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
