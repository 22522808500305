import Dra from "assets/images/Doctores/DoctoraMirta.png";


export default function ReseñaDoctor() {

  return (
    <div>
      <div className="container">
        <div className="d-flex mt-4" >
          <div className="pt-5">
            <div className="text-rosa text-medium">Confía en la Experiencia de la</div>
            <div className="text-big text-cafe text-bolder my-5" style={{lineHeight:'1'}}>Dra. Mirta de Lourdes Pérez Olivos</div>
            <div className="text-rosa text-medium mb-3">Cuidado Dermatológico Avanzado para toda la Familia</div>
            <div className="text-rosa" style={{textAlign:'justify'}}>
              En Peroli, la Dra. Mirta de Lourdes Pérez Olivos lidera nuestro equipo con su reconocida trayectoria en Dermatología.

              Con certificaciones en Tricología, Dermatología Estética y Dermatología Pediátrica, ofrece soluciones personalizadas para el cuidado de la piel, el cabello y las uñas.

              Su experiencia incluye tratamientos avanzados contra la caída del cabello (alopecia), asi como terapias estéticas que mejoran la apariencia y la salud de la piel. Además, con un enfoque sensible y empático, se dedica al cuidado de la piel de los más pequeños, abordando afecciones comunes de manera integral.
            </div>
          </div>
          <div className="doctora">
            <img src={Dra} alt="Doctora Mirta"/>
          </div>
        </div>
      </div>
    </div>
  );
}
