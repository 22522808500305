
function MisionVision() {
    return (
        <>
            <div className="bg-cafe">
                <div className="container">
                    <div className="d-flex" style={{ padding: '50px' }}>
                        <div className="text-rosa text-medium text-bolder mx-5" style={{ width: '50%' }}>Nuestra Misión</div>
                        <div className="text-rosa">Otorgar atención médica de calidad, con recursos especializados y tecnología de vanguardia; fomentando en su personal el humanismo, desarrollo, innovación y excelencia, para lograr la seguridad del paciente, su satisfacción y confianza.</div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container">
                    <div className="d-flex" style={{ padding: '50px' }}>
                        <div className="text-rosa text-medium text-bolder mx-5" style={{ width: '50%' }}>Nuestra Visión</div>
                        <div className="text-rosa">Ser una empresa reconocida por sus pacientes, clientes, profesionales y en el entorno social como una organización excelente, responsable, eficiente y competitiva, comprometida con la seguridad y la salud de las personas.</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MisionVision